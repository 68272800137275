<template>
  <div class="app_box">
    <div class="tab_tag_">
      <div class="tab_tag_s">
        <div
          class="tab_tag"
          @click="picked_id = 1"
          :class="picked_id == 1 ? 'tag_picked_css' : ''"
        >
          自动识别
        </div>
        <div
          class="tab_tag"
          @click="picked_id = 2"
          :class="picked_id == 2 ? 'tag_picked_css' : ''"
        >
          手动调整
          <el-tooltip
            class="item"
            effect="dark"
            content="若需求自动识别有误，可通过切换手动调整来补充需求"
            placement="right"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="app_css" v-if="picked_id == 1">
      <div
        :class="
          tab_or
            ? 'bottom_css'
            : !tab_or && identifyList.length > 0
            ? 'top_css'
            : 'bottom_css'
        "
      >
        <div class="input_css">
          <div
            class="cite_input_css"
            style="padding: 10px 0 0 0"
            v-if="cite_data_copy.task_id"
          >
            <div class="cite_css">
              <i class="el-icon-close cite_icon" @click="close_icon"></i>
              <div class="wenxian_li_s quotbox">
                <div
                  class="wenxian_li wenxian_txt_css"
                  style="color: rgb(181 181 181); line-height: 15px !important"
                >
                  <span class="wenxian_txt_css"
                    ><span class="li_tit" style="color: #bcbec2"
                      >需求类型：</span
                    >
                    {{ cite_data_copy.demand_code }}
                  </span>
                </div>
                <div
                  class="wenxian_li"
                  style="color: rgb(181 181 181); line-height: 15px !important"
                >
                  <span class="wenxian_txt_css"
                    ><span class="li_tit" style="color: #bcbec2"
                      >需求内容：</span
                    >
                    {{ cite_data_copy.content }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="input_css_pc">
            <el-input
              type="textarea"
              :rows="4"
              :placeholder="`可批量查询文献，每条需求以换行结束，需求示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`"
              v-model="textarea_txt"
              class="textarea_css"
              ref="textareaInput"
              style="border: none"
              @input="adjustDivHeight"
              @keydown.native="handleKeydown"
            >
              <!-- class="textarea_css" -->
              <!-- <img
                slot="suffix"
                @click="lookup_sub"
                class="btn_icon_img"
                src="https://xcx.newacademic.net/video/icon/fasong_.png"
                alt=""
              /> -->
            </el-input>
            <div class="btn_css">
              <div class="operate_txt">shift+enter：换行输入 enter：提交</div>
              <!-- <img
                @click="lookup_sub"
                class="btn_icon_img"
                src="https://xcx.newacademic.net/video/icon/fasong_.png"
                alt=""
              /> -->
              <el-button
                :class="[textarea_txt.trim() && 'active']"
                @click="lookup_sub"
              >
                提交
              </el-button>
            </div>
          </div>
          <!-- <img
            class="btn_icon_img history_css"
            src="https://xcx.newacademic.net/video/icon/history_.png"
            alt=""
            @click="goHistory"
          /> -->
        </div>
      </div>
      <div
        class="data_css"
        :class="
          tab_or
            ? 'bottom_list_css'
            : !tab_or && identifyList.length > 0
            ? 'top_list_css'
            : 'bottom_list_css'
        "
      >
        <div
          class="identify_list_css"
          v-if="!tab_or && identifyList.length > 0"
        >
          <h2 class="tit_txt">识别结果</h2>
          <div class="identify_li_s">
            <div
              class="identify_li_css"
              v-for="(item, index) in identifyList"
              :key="index"
            >
              <van-swipe-cell>
                <div class="li_css">
                  <el-checkbox
                    class="checkbox_col"
                    v-model="item.checked"
                    :disabled="item.disabled"
                    @change="checked_change(index)"
                  ></el-checkbox>
                  <div class="li_box">
                    <div class="li_box_tag">
                      <el-tag size="medium" @click="edit_demand(item, index)">{{
                        item.name
                      }}</el-tag>
                      <el-tag size="medium" @click="priority_edit(index)">{{
                        item.priority
                      }}</el-tag>
                      <el-tag size="medium" @click="remarkEdit(item, index)">{{
                        item.remark.length > 0 ? "已备注" : "备注"
                      }}</el-tag>
                      <span
                        v-if="item.resultMsg == ' ✔ 提交成功'"
                        style="
                          color: green;
                          font-size: 12px;
                          display: inline-block;
                        "
                        >{{ item.resultMsg }}</span
                      >
                      <span
                        v-else
                        style="
                          color: red;
                          font-size: 12px;
                          display: inline-block;
                        "
                        >{{
                          item.resultMsg.length > 20
                            ? " ✘ 提交失败"
                            : item.resultMsg
                        }}</span
                      >
                    </div>
                    <div class="li_box_txt">
                      {{ item.text }}
                    </div>
                  </div>
                  <div class="li_btn">
                    <img
                      src="../../../assets/imgs/app/edit.png"
                      @click="edit_demand(item, index)"
                      alt=""
                    />
                    <img
                      src="../../../assets/imgs/app/arrow_left.png"
                      style="width: 21px; margin-left: 10px"
                      alt=""
                    />
                  </div>
                </div>
                <template #right>
                  <!-- <van-button square type="danger" text="删除" />
                  <van-button square type="primary" text="编辑" /> -->
                  <div class="right_css">
                    <div
                      class="right_btn"
                      style="background: #f56c6c"
                      @click="del_demand(item, index)"
                    >
                      删除
                    </div>
                    <div
                      class="right_btn"
                      style="
                        background: #67c23a;
                        border-start-end-radius: 5px;
                        border-end-end-radius: 5px;
                      "
                      @click="edit_demand(item, index)"
                    >
                      编辑
                    </div>
                  </div>
                </template>
              </van-swipe-cell>
            </div>
          </div>
          <div class="footer_submit" v-if="identifyList.length > 0">
            <div class="clear_result" @click="clear_result">清空结果</div>
            <div class="merge_result" @click="merge_demand">需求合并</div>
            <div class="sumbit_selected" @click="handleSelect">提交</div>
          </div>
        </div>
        <div
          class="ai_css"
          v-if="tab_or || (!tab_or && identifyList.length <= 0)"
        >
          <div
            class="message_list_css"
            id="myDiv"
            @scroll="onScroll"
            ref="myDiv"
            style="padding: 10px 20px"
            :style="myDivStyle"
          >
            <div
              class="down_css"
              v-loading="down_loading"
              element-loading-text="数据加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
            <div v-for="(item, index) in messageData" :key="index">
              <div
                class="time_css"
                v-if="firstIndex_s(item.date_time) == index"
              >
                {{ item.date_time }}
              </div>
              <div
                class="message_li_css"
                :style="item.types == 1 ? 'justify-content: flex-end;' : ''"
              >
                <img
                  style="
                    width: 40px;
                    height: 40px;
                    position: relative;
                    left: -5px;
                  "
                  v-if="item.types !== 1"
                  src="../../../assets/imgs/app/robot_icon.png"
                  alt=""
                />
                <div
                  class="msg_txt_"
                  :style="
                    item.types == 1
                      ? 'max-width: calc(100% - 50px);min-width: 70px'
                      : 'width: calc(100% - 50px);'
                  "
                >
                  <!--  -->
                  <div class="msg_tit" v-if="item.types !== 1">
                    <div class="msg_name">图书馆参考咨询员</div>
                  </div>
                  <div v-if="item.types == 2" class="msg_txt_css">
                    <div
                      class="wenxian_"
                      v-if="
                        item.message.processing &&
                        item.message.processing.length > 0
                      "
                    >
                      <div class="literature_list_css">
                        <div
                          class="literature_li"
                          v-for="(val, key, index) in item.message.processing"
                          :key="index"
                        >
                          <div class="li_text_css">
                            ---{{ val.demand_code }}---
                          </div>
                          <div class="li_text_css">
                            <span v-html="val.content"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="public_txt"
                        v-if="!isValidJSON(item.message.text)"
                        style="margin-top: 15px"
                      >
                        {{ item.message.text }}
                      </div>
                    </div>

                    <div
                      v-if="item.message.quote && item.message.quote.task_id"
                    >
                      <div class="cite_css">
                        <div class="wenxian_li_s">
                          <div class="wenxian_li">
                            <span class="li_tit">需求类型：</span
                            >{{ item.message.quote.demand_code }}
                          </div>
                          <div class="wenxian_li">
                            <span class="li_tit">需求内容：</span
                            >{{ item.message.quote.content }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="public_txt"
                        v-html="item.message.text.replace(/\n/g, '<br>')"
                      ></div>
                    </div>

                    <div
                      v-if="
                        item.message.success && item.message.success.length > 0
                      "
                    >
                      <div
                        class="wenxian_"
                        v-for="(val, index) in item.message.success"
                        :key="index"
                        style="margin-bottom: 2px; width: 600px"
                      >
                        <div class="wenxian_li_s">
                          <div class="wenxian_li">
                            <div class="wenxian_li" v-if="val.demand_code">
                              <span class="li_tit">需求类型：</span>
                              {{ val.demand_code }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">需求内容：</span>
                              {{ val.original }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">识别内容：</span>
                              {{ val.content }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">处理状态：</span>
                              处理成功
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">处理时间：</span>
                              {{ val.complete_time }}
                            </div>
                          </div>
                        </div>
                        <div class="wenxian_btn_s">
                          <div
                            class="wenxian_btn"
                            @click="openLink(val.short_link)"
                            style="background: #5a79f4"
                            v-if="val.short_link.length > 0"
                          >
                            链接地址
                          </div>
                          <div
                            class="wenxian_btn"
                            style="background: #ec808d"
                            @click="errFeedback(val)"
                          >
                            错误反馈
                          </div>
                          <div
                            v-if="val.similar_url"
                            class="wenxian_btn"
                            style="background: #5a79f4"
                            @click="openSimilar(val)"
                          >
                            相关图书
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="item.message.fail && item.message.fail.length > 0"
                    >
                      <div
                        class="wenxian_"
                        v-for="(val, index) in item.message.fail"
                        :key="index"
                        :style="{
                          borderBottom:
                            index < item.message.fail.length - 1
                              ? '1px dashed #ccc'
                              : '',
                        }"
                      >
                        <div class="wenxian_li_s">
                          <div class="wenxian_li">
                            <div class="wenxian_li" v-if="val.demand_code">
                              <span class="li_tit">需求类型：</span>
                              {{ val.demand_code }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">需求内容：</span>
                              {{ val.original }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">识别内容：</span>
                              {{ val.content }}
                            </div>
                            <div class="wenxian_li">
                              <span class="li_tit">处理状态：</span>
                              处理失败
                            </div>
                            <div class="wenxian_li" v-if="val.fail_reason">
                              <span class="li_tit">失败原因：</span>
                              {{ val.fail_reason }}
                            </div>
                          </div>
                        </div>
                        <div class="wenxian_btn_s" v-if="val.similar_url">
                          <div
                            class="wenxian_btn"
                            style="background: #5a79f4"
                            @click="openSimilar(val)"
                          >
                            相似推荐
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        item.message.success.length == 0 &&
                        item.message.fail.length == 0 &&
                        item.message.processing.length == 0 &&
                        !item.message.quote.task_id
                      "
                      style="margin-left: 0px"
                    >
                      <div>{{ item.message.text }}</div>
                      <p
                        v-if="
                          item.message.text ===
                          '系统判断您的内容 【可能不是文献需求】，因此暂未处理。'
                        "
                      >
                        <b>如果消息被误判：</b>
                        <span>
                          <b
                            style="color: #96548e; cursor: pointer"
                            @click="forceSubmintHandle(item)"
                          >
                            &gt;&gt;点击此处&lt;&lt;
                          </b>
                          强行提交文献需求
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    v-else
                    class="msg_txt_css"
                    :style="
                      item.types == 1 ? 'background: #D59DB5;width: 100%' : ''
                    "
                  >
                    <div
                      class="cite_css"
                      v-if="item.message.quote && item.message.quote.task_id"
                    >
                      <div class="wenxian_li_s">
                        <div class="wenxian_li">
                          <span class="li_tit">需求类型：</span
                          >{{ item.message.quote.demand_code }}
                        </div>
                        <div class="wenxian_li">
                          <span class="li_tit">需求内容：</span
                          >{{ item.message.quote.content }}
                        </div>
                      </div>
                    </div>

                    <div
                      style="margin-left: 0px"
                      v-html="item.message.text.replace(/\n/g, '<br>')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="submit_or && cue_text_copy"
              class="message_li_css"
              style="justify-content: flex-end"
            >
              <div class="msg_txt_">
                <div class="msg_txt_css" style="background: #d59db5">
                  {{ cue_text_copy }}
                </div>
              </div>
            </div>

            <div v-if="submit_or" class="message_li_css">
              <img
                style="
                  width: 40px;
                  height: 40px;
                  position: relative;
                  left: -5px;
                "
                src="../../../assets/imgs/app/robot_icon.png"
                alt=""
              />
              <div class="msg_txt_" style="width: calc(100% - 50px)">
                <div class="msg_tit">
                  <div class="msg_name">图书馆参考咨询员</div>
                </div>
                <div class="msg_txt_css" style="position: relative">
                  {{ cue_text
                  }}<span v-if="!cue_text" class="typing-caret">_</span>
                </div>
              </div>
            </div>

            <div
              class="up_css"
              v-loading="up_loading"
              element-loading-text="数据加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="retrieval_css" v-if="picked_id == 2">
      <RETRIEVAL @submitHandle="submitHandle"></RETRIEVAL>
    </div>
    <div class="history_search_css" v-if="history_or"></div>
    <van-dialog
      :value="show"
      :title="dialog_tit"
      show-cancel-button
      confirm-button-text="确定"
      cancel-button-text="取消"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="edit_ruleForm_css"
      >
        <el-form-item label="需求类型" prop="code">
          <el-select
            v-model="ruleForm.code"
            size="medium"
            placeholder="请选择类型"
            popper-class="code_css"
            style="width: 70%"
          >
            <el-option
              v-for="item in objectArray"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="需求内容" prop="text">
          <el-input
            type="textarea"
            v-model="ruleForm.text"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请填写需求内容..."
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="扩展信息" prop="supplement">
          <el-input
            type="textarea"
            v-model="ruleForm.supplement"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="可添加扩展信息"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
    </van-dialog>
    <van-dialog
      :value="show_remark"
      title="添加备注"
      show-cancel-button
      confirm-button-text="确定"
      cancel-button-text="取消"
      @confirm="onConfirm_remark"
      @cancel="onCancel_remark"
    >
      <div class="remark_box_css">
        <el-input
          type="textarea"
          v-model="remark_text"
          :autosize="{ minRows: 3, maxRows: 5 }"
          placeholder="可添加您的备注"
          style="width: 100%"
        ></el-input>
        <div class="remark_tag_s">
          <p
            style="
              line-height: 30px;
              font-size: 13px;
              color: #555;
              margin-top: 10px;
            "
          >
            常用备注
          </p>
          <el-tag
            size="medium"
            v-for="(item, index) in remark_tag_txt"
            :key="index"
            @click="remark_text = remark_text + item"
            >{{ item }}</el-tag
          >
        </div>
      </div>
    </van-dialog>

    <el-dialog
      title="链接地址"
      :visible.sync="dialogVisible"
      :width="dialogUrl.length > 1 ? '460px' : '460px'"
      @close="closeDialog"
      custom-class="dialog_file_css"
      :show-close="false"
    >
      <div class="link_css">
        <div
          class="link_url_css"
          v-for="(item, index) in dialogUrl"
          :key="index"
        >
          <div class="link_url">
            <span class="url_tit"
              >{{
                dialogUrl.length > 1
                  ? "链接" + numberToChinese(index + 1)
                  : "链接"
              }}
              <span v-if="dialogUrl.length <= 1">&ensp;</span>
            </span>
            <span id="copy" class="inputText"
              ><span class="urlText" style="" @click="openLink01(item)">{{
                item
              }}</span></span
            >
            <img
              @click="copyLink01(item)"
              style="cursor: pointer"
              src="../../../assets/imgs/2.2/copy.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_btn" @click="closeDialog">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { Toast } from "vant";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
import $ from "jquery";
import RETRIEVAL from "./retrieval.vue";
import {
  Identify,
  submit,
  categoryList,
  messagerecordData,
  messageDetail,
  manual,
  errorFeedback,
  newDemandPost,
} from "@/Api/manage";
import { personalMessage } from "@/Api/userMessage";
export default {
  inject: ["reloads"],
  data() {
    return {
      textarea_txt: "",
      //   DOI:10.31486/toj.20.0025\nPMID:34239371\n

      tab_or: true,
      message: "",
      identifyList: [],
      downloadList: [],
      legalArr: [],
      array: [],
      show: false,
      dialog_tit: "编辑需求",
      objectArray: [],
      codeArray: [],
      ruleForm: {
        code: "",
        text: "",
        supplement: "",
      },
      edit_index: 0,
      rules: {
        code: [
          { required: true, message: "请选择需求类型", trigger: "change" },
        ],
        text: [{ required: true, message: "请填写需求内容", trigger: "blur" }],
        // supplement: [
        //   { required: true, message: "请填写扩展信息", trigger: "blur" },
        // ],
      },
      headers: {
        token: localStorage.getItem("token"),
      },
      download_num: 0,
      remark_text: "",
      show_remark: false,
      remark_tag_txt: ["正式版", "补充材料", "查收查引"],
      messageData: [],
      message_count: 0,
      messageQuery: {
        size: 10,
        new_data: "",
        search: "",
        last_id: "",
        direction: "up",
        old_year: "",
      },
      submit_or: false,
      cue: {
        type: 1,
        text: "",
        organ_code: "BJYKQXKJ001",
      },
      organ_code_copy: "",
      cue_text: "",
      startY: 0,
      isAtTop: true,
      isAtBottom: false,
      down_loading: false,
      down_text: "",
      up_loading: false,
      up_text: "",
      cue_text_copy: "",
      result_data: {},
      scrollHeight: 0,
      cite_data_copy: {},
      merge_ids: [],
      submitList_copy: [],
      fileList: [],
      shake_or: false,
      myDivStyle: {
        height: "calc(100vh - 275px)",
        overflowX: "hidden",
        overflowY: "scroll",
      },
      history_or: false,
      picked_id: 1,
      intervalId: null,
      timer: "",
      dialogUrl: [],
      dialogVisible: false, // 链接地址
      ongoing: false,
    };
  },
  created() {
    // 首页跳转进入
    if (sessionStorage.getItem("mes")) {
      let mes = JSON.parse(sessionStorage.getItem("mes"));
      this.organ_code_copy = mes.org_code;
      this.cue.organ_code = mes.org_code;
    } else {
      this.getMessage();
    }
    let searchText = sessionStorage.getItem("searchText");
    if (searchText) {
      this.textarea_txt = searchText;
      sessionStorage.removeItem("searchText");
      this.lookup_sub();
      return;
    }
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
      if (this.$route.query.data) {
        let text_val = this.$route.query.data.replace(/\|/g, "\n");
        let json_ = {
          text_val: text_val,
          or: this.$route.query.or,
        };
        window.sessionStorage.removeItem("id_data");
        window.sessionStorage.setItem("text_data", JSON.stringify(json_));
        let that = this;
        that.$router.replace(
          {
            path: "/qinli_app",
            query: {
              token: this.$route.query.token,
              reload: new Date().getTime(),
            },
          },
          () => {
            that.reloads(); //刷新页面
          }
        );
      } else if (this.$route.query.id || this.$route.query.date) {
        let json_ = {
          id: this.$route.query.id ? this.$route.query.id : "",
          date: this.$route.query.date ? this.$route.query.date : "",
        };
        window.sessionStorage.removeItem("text_data");
        window.sessionStorage.setItem("id_data", JSON.stringify(json_));
        let that = this;
        setTimeout(function () {
          that.$router.replace(
            {
              path: "/qinli_app",
              query: {
                token: that.$route.query.token,
                reload: new Date().getTime(),
              },
            },
            () => {
              that.reloads(); //刷新页面
            }
          );
        }, 500);
      } else {
        if (window.sessionStorage.getItem("text_data")) {
          let text_ = JSON.parse(window.sessionStorage.getItem("text_data"));
          if (text_.or == "true") {
            this.tab_or = false;
          } else {
            this.tab_or = true;
          }
          this.textarea_txt = text_.text_val;

          this.lookup_sub();
        } else if (window.sessionStorage.getItem("id_data")) {
          let id_data = JSON.parse(window.sessionStorage.getItem("id_data"));
          if (id_data.id) {
            this.messageQuery.last_id = id_data.id - 0 - 1;
          } else if (id_data.date) {
            this.messageQuery.new_data = id_data.date;
          }
          this.messageQuery.direction = "down";
          this.tab_or = true;
          this.get_messageRecord_history();
        }
      }
    } else {
      wx.miniProgram.navigateTo({
        url: "/pages/my/my", // 指定跳转至小程序页面的路径
        success: (res) => {
          console.log(res); // 页面跳转成功的回调函数
        },
        fail: (err) => {
          console.log(err); // 页面跳转失败的回调函数
        },
      });
    }
    this.get_data();
    this.dataRefreh();
  },
  mounted() {
    // 监听当前页面 显示状态
    window.addEventListener("visibilitychange", this.hanldeVisiblityChange);
    // 当页面被销毁时 移除监听
    this.$on("hook:beforeDestroy", () => {
      // console.info("ws 我被销毁了, 移除监听>>>");
      window.removeEventListener(
        "visibilitychange",
        this.hanldeVisiblityChange
      );
    });
    if (this.tab_or && !window.sessionStorage.getItem("id_data")) {
      this.get_messageRecord();
    }
    this.$nextTick(() => {
      if (!window.sessionStorage.getItem("id_data")) {
      }
    });
  },
  watch: {
    messageData() {
      this.$nextTick(() => {
        if (
          !this.messageQuery.last_id &&
          !window.sessionStorage.getItem("id_data")
        ) {
          // this.scrollToBottom();
        }
        //
      });
    },
  },

  methods: {
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新" + new Date());
        this.flushedAutomatic(); //加载数据函数
      }, 5000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    hanldeVisiblityChange() {
      if (document.visibilityState === "hidden") {
        // console.log("ws  hidden>>>关闭定时器");
        this.clear();
      } else if (document.visibilityState === "visible") {
        // console.log("ws  visible>>>启动定时器");
        this.dataRefreh();
      }
    },
    getMessage() {
      if (localStorage.getItem("token")) {
        personalMessage()
          .then((res) => {
            if (res.data.data) {
              sessionStorage.setItem("mes", JSON.stringify(res.data.data));
              res.data.data;
              this.organ_code_copy = res.data.data.org_code;
              this.cue.organ_code = res.data.data.org_code;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    adjustDivHeight() {
      this.$nextTick(() => {
        let textareaHeight =
          this.$refs.textareaInput.$el.querySelector("textarea").scrollHeight;
        if (this.textarea_txt == "") {
          textareaHeight = 0;
        } else {
          textareaHeight = textareaHeight - 50;
        }

        if (textareaHeight >= 86) {
          textareaHeight = 86;
        }

        if (this.cite_data_copy.text) {
          textareaHeight = textareaHeight + 50;
        }

        let newHeight = `calc(100vh - 270px)`;
        if (this.cite_data_copy.text) {
          newHeight = `calc(100vh - 310px)`;
        }
        this.myDivStyle.height = newHeight;
      });
    },
    scrollToBottom() {
      const container = this.$refs.myDiv;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    tab_type() {
      this.tab_or = !this.tab_or;
      this.textarea_txt = "";
      if (this.tab_or) {
        this.messageQuery = {
          size: 10,
          new_data: "",
          search: "",
          last_id: "",
          direction: "up",
        };
        this.get_messageRecord();
      } else {
        this.identifyList = [];
      }
    },
    get_data() {
      categoryList()
        .then((res) => {
          if (res.data.error_code == 0) {
            let resData = res.data.data;
            let keys = Object.keys(resData);
            let arr = [];
            let objArr = [];

            keys.forEach((item) => {
              let obj = {};
              obj.code = item;
              obj.name = resData[item];
              obj.isFirst = false;
              objArr.push(obj);
              arr.push(resData[item]);
            });
            this.array = arr;

            this.objectArray = objArr;
            this.codeArray = keys;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    lookup_sub() {
      if (this.shake_or) {
        return;
      }
      if (!this.textarea_txt) {
        this.$message({
          message: "需求为空！",
          type: "warning",
        });
        return;
      }
      this.clear(); // 清除定时器
      if (!this.tab_or) {
        Identify({ text: this.textarea_txt })
          .then((res) => {
            if (res.data.error_code == 0) {
              this.dataRefreh(); //启动定时器
              let newArry = [];
              let downArry = [];
              let count = 0;

              res.data.data.forEach((item) => {
                for (let index = 0; index < item.result_list.length; index++) {
                  let obj = {};
                  obj.text = item.result_list[index].text;
                  obj.code = item.result_list[index].code;
                  obj.name = item.result_list[index].name;
                  obj.supplement = "";
                  if (item.result_list[index].code == "Other") {
                    obj.resultMsg = "请核对需求内容 ！";
                  } else {
                    obj.resultMsg = "";
                  }
                  obj.index = count;
                  obj.priority = "一般";
                  obj.remark = "";
                  obj.highlight = item.highlight;
                  // 单次提交限制10条需求
                  if (obj.code == "Other") {
                    obj.checked = false;
                    obj.disabled = false;
                  } else if (obj.code !== null && downArry.length < 10) {
                    downArry.push(obj);
                    obj.checked = true;
                    obj.disabled = false;
                  } else {
                    obj.checked = false;
                    obj.disabled = true;
                  }
                  newArry.push(obj);
                  count++;
                }
              });
              if (downArry.length == 10) {
                newArry.forEach((item, index) => {
                  if (item.checked == false) {
                    newArry[index].disabled = true;
                  }
                });
              }
              let legalArr = newArry.filter((item) => {
                return this.array.indexOf(item.name) > -1;
              });
              if (downArry.length == 10) {
                this.$message({
                  message: "单次最多提交10条！",
                  type: "warning",
                });
              }

              this.identifyList = newArry;
              this.downloadList = downArry;
              this.download_num = this.downloadList.length;
              this.legalArr = legalArr;
              this.toView = "identify";
              if (window.sessionStorage.getItem("text_data")) {
                window.sessionStorage.setItem("text_data", "");
              }
            } else {
              this.message = res.data.msg;
              this.$message({
                message: this.message,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (!this.cite_data_copy.id) {
          this.cue.quote_id = "";
        }
        this.cue.force_type = "";
        if (Object.keys(this.cite_data_copy).length > 0) {
          this.confirmErrFeedback(
            this.textarea_txt,
            this.cite_data_copy.task_id
          );
        } else {
          this.consultationAi();
        }
        if (window.sessionStorage.getItem("text_data")) {
          window.sessionStorage.setItem("text_data", "");
        }
      }
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          // Shift + Enter：换行（默认行为，无需处理）
        } else {
          // Enter：提交内容
          event.preventDefault(); // 阻止默认的回车行为（如提交表单）
          this.lookup_sub();
        }
      }
    },
    consultationAi(type_) {
      if (this.shake_or) {
        return;
      } else {
        this.shake_or = true;
      }
      let that = this;
      that.cue_text = "";
      that.submit_or = true;
      if (that.textarea_txt) {
        that.cue.text = that.textarea_txt;
        that.cue_text_copy = that.textarea_txt;
      }

      let cue = {
        type: that.cue.type,
        text: that.cue.text,
        // quote_id: that.cue.quote_id ? that.cue.quote_id : null,
        organ_code: that.organ_code_copy,
        // force_type: that.cue.force_type ? that.cue.force_type : "",
        force_type: 2,
        type: 2,
        is_artificial: that.cue.is_artificial ? that.cue.is_artificial : 0,
        force_submint: type_ == "compulsion" ? true : false,
      };
      that.textarea_txt = "";

      setTimeout(() => {
        that.scrollToBottom();
      }, 100);
      this.adjustDivHeight();
      that.returnMessageAjax = $.ajax({
        // url: "http://192.168.66.18:8007/api/user/consultation/",
        // url: "https://ql-test.newacademic.net/api/user/consultation/",
        url: process.env.VUE_APP_API_BASE_URL + "user/consultation/",
        // url: "https://xcx.newacademic.net/api/user/consultation/",
        data: JSON.stringify(cue),
        timeout: 50000, // 请求超时时间
        type: "Post",
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          token: localStorage.getItem("token"),
        },
        // dataType: "json",
        processData: false, // 不处理数据
        contentType: false, // 不设置内容类型
        xhrFields: {
          onprogress: function (e) {
            that.cue_text = "_";
          },
          onload: function (e) {
            console.log(e);
          },
        },
        timeout: 1000 * 60 * 2,

        complete: function (XMLHttpRequest, status) {
          that.dataRefreh(); //启动定时器
          that.cue_text_copy = "";
          that.messageQuery.last_id = "";
          that.messageQuery.direction = "up";
          that.messageQuery.old_year = "";
          that.cue.quote_id = "";
          that.cue.is_artificial = "";
          that.cue.force_type = "";
          that.shake_or = false;
          if (XMLHttpRequest.responseJSON) {
            let responseJson = XMLHttpRequest.responseJSON;
            if (responseJson.error_code === 1) {
              that.$message({
                message: responseJson.msg,
                type: "warning",
              });
            }
          }

          that.get_messageRecord();
          if (Object.keys(that.cite_data_copy).length > 0) {
            that.manual_dispose(
              that.cite_data_copy.text.task_id,
              that.cue.text
            );
          }
        },
      });
    },
    manual_dispose(task_id, msg) {
      let anew_ = {
        task_id: task_id,
        source: 0,
        msg: msg,
      };

      manual(anew_)
        .then((res) => {
          if (res.data.error_code == 0) {
          }
          that.cite_data_copy = {};
        })
        .catch((err) => {
          that.cite_data_copy = {};
        });
    },
    clear_result() {
      this.identifyList = [];
    },
    edit_demand(row, i) {
      this.edit_index = i;
      this.ruleForm = {
        code: row.code,
        text: row.text,
        supplement: row.supplement,
      };
      this.dialog_tit = "编辑需求";
      this.show = true;
    },
    merge_demand() {
      this.submitList_copy = [];
      this.merge_ids = [];
      this.identifyList.forEach((item, index) => {
        if (item.checked) {
          this.submitList_copy.push(item);
          this.merge_ids.push(index);
        }
      });
      if (this.submitList_copy.length <= 1) {
        // Toast.fail("请至少选中两项");
        this.$message({
          message: "请至少选中两项",
          type: "warning",
        });
      } else {
        this.ruleForm = {
          code: this.submitList_copy[0].code,
          text: this.submitList_copy.map((item) => item.text).join(""),
          supplement: this.submitList_copy
            .map((item) => item.supplement)
            .join("\n"),
        };
        this.dialog_tit = "需求合并";
        this.show = true;
      }
    },

    remarkEdit(row, i) {
      this.edit_index = i;
      this.remark_text = row.remark;
      this.show_remark = true;
    },
    onCancel_remark() {
      this.show_remark = false;
    },
    onConfirm_remark() {
      this.identifyList[this.edit_index].remark = this.remark_text;
      this.show_remark = false;
    },
    checked_change(i) {
      if (this.identifyList[i].checked) {
        this.download_num = this.download_num + 1;
      } else {
        this.download_num = this.download_num - 1;
      }
      if (this.download_num >= 10) {
        this.identifyList.forEach((item, index) => {
          if (!item.checked) {
            item.disabled = true;
          }
        });
      } else {
        this.identifyList.forEach((item, index) => {
          item.disabled = false;
        });
      }
    },
    onConfirm() {
      if (this.dialog_tit == "编辑需求") {
        this.identifyList[this.edit_index].code = this.ruleForm.code;
        this.identifyList[this.edit_index].text = this.ruleForm.text;
        this.identifyList[this.edit_index].supplement =
          this.ruleForm.supplement;
        //   if (this.identifyList[this.edit_index].checked) {
        //     this.downloadList[this.edit_index].code = this.ruleForm.code;
        //     this.downloadList[this.edit_index].text = this.ruleForm.text;
        //     this.downloadList[this.edit_index].supplement =
        //       this.ruleForm.supplement;
        //   }

        this.objectArray.forEach((item, index) => {
          if (item.code == this.ruleForm.code) {
            this.identifyList[this.edit_index].name = item.name;
            //   if (this.identifyList[this.edit_index].checked) {
            //     this.downloadList[this.edit_index].name = item.name;
            //   }
          }
        });
        this.show = false;
      } else {
        Dialog.confirm({
          message: "确定合并？",
        })
          .then(() => {
            // this.identifyList.splice(i, 1);
            const filteredArray = this.identifyList.filter(
              (item, index) => !this.merge_ids.includes(index)
            );
            this.identifyList = filteredArray;
            let new_merge = {
              code: this.ruleForm.code,
              text: this.ruleForm.text,
              supplement: this.ruleForm.supplement,
              priority: this.submitList_copy[0].priority,
              name: this.submitList_copy[0].name,
              remark: this.submitList_copy
                .map((item) => item.remark)
                .join("\n"),
              checked: false,
              disabled: false,
              resultMsg: "",
            };
            this.identifyList.push(new_merge);
            this.show = false;
          })
          .catch(() => {
            // on cancel
            this.show = false;
          });
      }
    },
    onCancel() {
      this.show = false;
    },
    del_demand(row, i) {
      Dialog.confirm({
        message: "确定删除吗？",
      })
        .then(() => {
          this.identifyList.splice(i, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    handleSelect() {
      if (this.download_num > 0) {
        let submitList = [];
        this.identifyList.forEach((item, index) => {
          if (item.checked) {
            if (item.code == null || item.code == "null") {
              this.identifyList[index].resultMsg = " ✘ 需求类型错误";
            } else {
              submitList.push({
                id: index,
                type_code: item.code,
                text: item.text, // 需求内容
                supplement: item.supplement,
                original_text: "<b>" + item.text + "</b>", // 高亮文本
                priority: item.priority, //优先、一般
                remark: item.remark, // 备注信息
              });
            }
          }
        });

        let sub = {
          demand: submitList,
          text: this.textarea_txt,
        };
        submit(sub)
          .then((res) => {
            if (res.data.error_code == 0) {
              let sub_data = res.data.data;
              for (var key in sub_data) {
                let i = submitList[key].id;
                if (sub_data[key].code == 0) {
                  // 提交成功
                  this.identifyList[i].resultMsg = " ✔ 提交成功";
                } else {
                  // 提交失败
                  if (sub_data[key].msg == "今日您中文书的提交数量已达上限") {
                    this.identifyList[i].resultMsg = " ✘ 今日中文书达上限";
                  } else if (
                    sub_data[key].msg == "今日您非中文书的提交数量已达上限"
                  ) {
                    // 达上限时
                    this.identifyList[i].resultMsg = " ✘ 今日已达上限";
                  } else if (sub_data[key].msg == "今日已提交过此需求") {
                    // 重复提交时
                    this.identifyList[i].resultMsg = " ✘ 重复提交";
                  } else if (
                    sub_data[key].msg == "未知原因提交失败" ||
                    sub_data[key].msg == "需求提交过快"
                  ) {
                    // 提交失败时
                    this.identifyList[i].resultMsg = " ✘ 提交失败";
                  } else {
                    this.identifyList[i].resultMsg = " ！" + sub_data[key].msg;
                  }
                }
                this.identifyList[i].disabled = true;
                this.identifyList[i].checked = false;
              }
              let exists = Object.values(sub_data).some(
                (item) => item.code === 0
              );
              if (exists) {
                this.messageQuery = {
                  size: 10,
                  new_data: "",
                  search: "",
                  last_id: "",
                  direction: "up",
                };
                this.textarea_txt = "";
                this.tab_or = !this.tab_or;
                this.get_messageRecord();
              }
            }
          })
          .catch((err) => {});
      } else {
        this.$message({
          message: "需求为空",
          type: "warning",
        });
      }
    },
    priority_edit(i) {
      if (this.identifyList[i].priority == "优先") {
        this.identifyList[i].priority = "一般";
      } else {
        this.identifyList[i].priority = "优先";
      }
    },
    get_messageRecord_history() {
      this.messageQuery.type = 2;
      messagerecordData(this.messageQuery)
        .then((res) => {
          if (res.data.error_code == 0) {
            res.data.data.data.forEach((item, index) => {
              item.message = JSON.parse(item.message);
            });
            this.messageData = res.data.data.data;
            this.message_count = res.data.data.count;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_messageRecord() {
      const container = this.$refs.myDiv;
      let prevScrollHeight = 0; // 获取添加新内容前的滚动高度
      let prevScrollTop = 0;
      if (container) {
        if (container.scrollHeight) {
          prevScrollHeight = container.scrollHeight;
        }
        // 获取添加新内容前的滚动高度
        if (container.scrollTop) {
          prevScrollTop = container.scrollTop;
        }
      }
      this.messageQuery.type = 2;
      this.ongoing = true;
      console.log("this.messageQuery.direction", this.messageQuery.direction);
      messagerecordData(this.messageQuery)
        .then((res) => {
          if (res.data.error_code == 0) {
            res.data.data.data.forEach((item, index) => {
              item.message = JSON.parse(item.message);
            });
            // console.log(this.messageQuery.old_year)
            if (this.messageQuery.last_id || this.messageQuery.old_year) {
              if (this.messageQuery.direction == "down") {
                // console.log("bbbbbbbbb", this.messageQuery.direction);
                this.messageData = [...this.messageData, ...res.data.data.data];
              } else if (this.messageQuery.direction == "up") {
                // console.log("cccccc", this.messageQuery.direction);
                this.messageData = [...res.data.data.data, ...this.messageData];
              }
            } else {
              // this.messageData = res.data.data.data;
              let array = [...this.messageData, ...res.data.data.data];
              const uniqueArray = array.filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              );
              this.messageData = uniqueArray;
              // that.data.literatureData = uniqueArray;
              this.$nextTick(() => {
                this.scrollToBottom();
              });
            }
            console.log("aaaaaaaa", this.messageData);
            this.$nextTick(() => {
              if (this.messageQuery.direction == "up") {
                // 计算新高度
                const newScrollHeight = container.scrollHeight;
                const heightDifference = newScrollHeight - prevScrollHeight;
                // 调整滚动位置
                container.scrollTop = prevScrollTop + heightDifference;
              }
              if (this.messageQuery.direction) {
                if (res.data.data.data.length == 0) {
                  if (this.messageQuery.direction == "down") {
                    this.down_text = "已到底部";
                    // Toast("当前为最新记录");
                  } else if (this.messageQuery.direction == "up") {
                    if (this.messageQuery.old_year) {
                      this.up_text = "已到顶部";
                    } else {
                      const lastYear = new Date().getFullYear() - 1;
                      this.messageQuery.old_year = lastYear;
                      this.messageQuery.last_id = "";
                      // console.log("aa");
                      this.get_messageRecord();
                    }
                  }
                } else {
                  if (this.messageQuery.direction == "down") {
                    this.down_text = "";
                  } else if (this.messageQuery.direction == "up") {
                    this.up_text = "";
                  }
                  if (
                    this.messageData.length > 0 &&
                    this.messageData.length < 10 &&
                    !this.messageQuery.old_year
                  ) {
                    const lastYear = new Date().getFullYear() - 1;
                    this.messageQuery.old_year = lastYear;
                    this.messageQuery.last_id = "";
                    this.messageQuery.direction = "up";
                    // console.log(
                    //   "this.messageQuery.direction",
                    //   this.messageQuery.direction
                    // );
                    this.get_messageRecord();
                  }
                }
              }
              this.message_count = res.data.data.count;
              this.submit_or = false;
              this.up_loading = false;
              this.down_loading = false;
            });
          }
          if (window.sessionStorage.getItem("id_data")) {
            window.sessionStorage.setItem("id_data", "");
          }
          if (
            this.messageQuery.last_id ||
            this.messageQuery.new_data ||
            this.messageQuery.old_year
          ) {
            this.messageQuery.last_id = "";
            this.messageQuery.new_data = "";
          } else {
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          }
          this.$nextTick(() => {
            this.ongoing = false;
          });
        })
        .catch((err) => {
          console.log(err);
          this.ongoing = false;
        });
    },
    firstIndex_s(date) {
      return this.messageData.findIndex((item) => item.date_time === date);
    },
    goHistory() {
      this.history_or = !this.history_or;
    },
    backCourse() {
      wx.miniProgram.navigateTo({
        url: "/pages/course/course",
        // 指定跳转至小程序页面的路径
        success: (res) => {
          console.log(res); // 页面跳转成功的回调函数
        },
        fail: (err) => {
          console.log(err); // 页面跳转失败的回调函数
        },
      });
    },
    showExample() {
      this.textarea_txt = `DOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`;
    },
    onTouchStart(event) {
      const touch = event.touches[0];
      this.startY = touch.clientY;
    },
    onTouchEnd(event) {
      const touch = event.changedTouches[0];
      const endY = touch.clientY;
      const div = this.$refs.myDiv;
      const scrollTop = div.scrollTop;
      const scrollHeight = div.scrollHeight;
      const clientHeight = div.clientHeight;
      this.isAtTop = scrollTop === 0;
      this.isAtBottom = scrollTop + clientHeight === scrollHeight;
      if (this.startY > endY + 30 && this.isAtBottom) {
        // 上拉动作
        console.log("Swiped up");
        this.handleSwipeUp();
      } else if (this.startY < endY - 30 && this.isAtTop) {
        // 下拉动作
        console.log("Swiped down");
        this.handleSwipeDown();
      }
    },
    handleSwipeUp() {
      // 处理上拉动作
      if (!this.down_text && !this.messageQuery.old_year) {
        this.up_loading = true;
        this.messageQuery.last_id =
          this.messageData[this.messageData.length - 1].id;
        this.messageQuery.direction = "down";
        this.get_messageRecord();
      }
    },
    handleSwipeDown() {
      // 处理下拉动作
      const container = this.$refs.myDiv;
      let prevScrollHeight = 0;
      if (container) {
        prevScrollHeight = container.scrollHeight;
        this.scrollHeight = container.scrollHeight;
      }
      if (!this.up_text) {
        this.down_loading = true;
        this.messageQuery.last_id = this.messageData[0].id;
        this.messageQuery.direction = "up";
        this.get_messageRecord();
      }
    },
    onScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop === 0) {
        this.handleSwipeDown();
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        this.handleSwipeUp();
      }
    },
    openLink(short_link) {
      if (short_link.length === 1) {
        window.open(short_link[0], "_blank");
      } else {
        this.dialogUrl = short_link;
        this.dialogVisible = true;
      }
    },
    // 打开相似图书
    openSimilar(val) {
      // console.log(val);
      // let url = "http://ql-test.newacademic.net:8001/#/booksSet?";

      // if (val.similar_id) {
      //   url = url + `similar_id=${val.similar_id}`;
      // } else if (val.TaoShuId) {
      //   url = url + `TaoShuId=${val.TaoShuId}`;
      // }
      // window.open(url, "_blank");

      // return;
      window.open(val.similar_url, "_blank");
    },
    closeDialog() {
      this.dialogVisible = false;
      this.dialogUrl = [];
    },
    numberToChinese(num) {
      const map = {
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "七",
        8: "八",
        9: "九",
        10: "十",
      };
      return map[num] || num; // 如果不在范围内，返回原值
    },
    openLink01(links) {
      window.open(links, "_blank");
    },
    copyLink01(item) {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = item;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 错误反馈
    errFeedback(val) {
      this.cite_data_copy = JSON.parse(JSON.stringify(val));
    },
    // 确认提交错误反馈
    confirmErrFeedback(msg, task_id) {
      setTimeout(() => {
        this.textarea_txt = "";
        this.cite_data_copy = {};
      }, 800);
      errorFeedback({
        task_id,
        msg,
      }).then((res) => {
        if (res.data.error_code == 0) {
          this.get_messageRecord();
        }
      });
    },
    close_icon() {
      this.cite_data_copy = {};
      this.adjustDivHeight();
    },
    progressfile() {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
    },
    succesfile(res) {
      if (res.error_code == 0) {
        if (res.data[1] == null || res.data[1].length == 0) {
          Toast("无法识别");
        } else {
          this.textarea_txt = "";
          res.data[1].map((item) => {
            this.textarea_txt += item.words + "\n";
          });
          this.lookup_sub();
          Toast.clear();
        }
      } else {
        Toast("无法识别");
      }
    },
    or_msg(text_s) {
      let count = 0;
      for (let key in text_s) {
        if (text_s[key].code === 0) {
          count++;
        }
      }
      return count;
    },
    or_msg_c(text_s, quote_text) {
      let count = true;

      for (let key in text_s) {
        if (text_s[key].text == quote_text) {
          count = false;
        }
      }
      return count;
    },
    getTimeText(argument) {
      var timeS = argument;
      var todayT, yestodayT;
      var timeCha = this.getTimeS(timeS);
      // 获取今天开始时间 (00:00:00.000) 的毫秒数
      var todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      // 从今天开始到现在的时间（以毫秒为单位）
      todayT = Date.now() - todayStart.getTime();
      // 昨天的时间（以毫秒为单位），从昨天开始算起
      yestodayT = todayT + 24 * 60 * 60 * 1000;
      // 提取时间部分 (HH:mm:ss)
      timeS = timeS.slice(-8);
      if (timeCha > yestodayT) {
        argument = argument.replace(/T/, " ");
        return argument.slice(5, 11) + timeS.slice(0, 5);
      }
      if (timeCha > todayT && timeCha < yestodayT) {
        return "昨天" + " " + timeS.slice(0, 5);
      }
      if (timeCha < todayT) {
        return timeS.slice(0, 2) >= 12
          ? "下午" +
              (timeS.slice(0, 2) == 12 ? 12 : timeS.slice(0, 2) - 12) +
              timeS.slice(2, 5)
          : "上午" + timeS.slice(0, 5);
      }
    },

    getTimeS(timeS) {
      // 将时间字符串转换为 Date 对象，考虑到时区问题
      timeS = timeS.replace(/T/, " ");
      timeS = timeS.replace(/[-]/g, "/");
      return Date.now() - new Date(timeS).getTime();
    },
    // 时间戳转年月日
    formatDate(data) {
      let now = new Date(data);
      var year = now.getFullYear(); //取得4位数的年份
      var month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      var date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      var hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      var minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      var second =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    formatTime(timeStr) {
      // 创建 Date 对象
      let date = new Date(timeStr.replace(/T/, " ").replace(/-/g, "/"));

      // 格式化为 YYYY-MM-DD HH:mm:ss
      let formattedDate =
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0") +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0") +
        ":" +
        String(date.getSeconds()).padStart(2, "0");
      return formattedDate;
    },
    formattedText(text) {
      // 替换特定 URL 为 <a> 标签，并将换行符替换为 <br>
      return text
        .replace("http://s.dic.cool/S/jwZKDP6B", (match) => {
          return `<a href="${match}" style="color: #409eff;" target="_blank">${match}</a>`;
        })
        .replace(/\n/g, "<br>");
    },
    flushedAutomatic() {
      let messageQuery_ = {
        size: 10,
        new_data: "",
        search: "",
        last_id: "",
        type: 2,
        direction: "up",
      };
      messagerecordData(messageQuery_)
        .then((res) => {
          if (res.data.error_code == 0) {
            res.data.data.data.forEach((item, index) => {
              item.message = JSON.parse(item.message);
            });
            const mergedArray = [
              ...new Map(
                [...this.messageData, ...res.data.data.data].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            if (this.messageData.length >= mergedArray.length) {
              return;
            }
            const container = document.getElementById("myDiv");
            if (!container) return;

            // 判断是否在最底部
            const isScrolledToBottom =
              container.scrollHeight - container.scrollTop ===
              container.clientHeight;
            this.messageData = mergedArray;
            this.$nextTick(() => {
              if (isScrolledToBottom) {
                container.scrollTop = container.scrollHeight;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitHandle() {
      this.picked_id = 1;
      setTimeout(() => {
        const container = document.getElementById("myDiv");
        container.scroll({
          top: container.scrollHeight,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.flushedAutomatic();
        }, 500);
      }, 0);
    },
    // 强制提交
    async forceSubmintHandle(item) {
      let res = await newDemandPost({
        demand: item.message.demand,
      });
      if (res.data.error_code === 0) {
        this.get_messageRecord();
      }
    },
    isValidJSON(str) {
      console.log(typeof str, typeof str == "object");
      if (typeof str !== "string") {
        return false;
      }
      try {
        const result = JSON.parse(str);

        return typeof result === "object" && result !== null;
      } catch (e) {
        return false;
      }
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      this.clear();
    }
  },
  components: {
    RETRIEVAL,
  },
};
</script>
  
<style  lang="less" scoped>
.app_box {
  background: #f9f0f4;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  height: calc(100vh - 120px);
}
.app_css {
  width: 100%;
  margin: 0 20%;
  position: relative;
}
.input_css {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  width: calc(100% - 10px);
  padding: 0 10px;
  position: relative;
  right: -20px;
  z-index: 2000;
}
.close_css {
  position: absolute;
  right: 60px;
  top: 3px;
  font-weight: 600;
  z-index: 100;
}

.btn_css {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  // justify-content: space-between;
  // align-items: center;
  // padding: 3px 0 0 0;
  // width: 30px;

  .btn_tab,
  .btn_icon {
    cursor: pointer;
    // display: flex;
    //
    // align-items: center;
  }
  .el-button {
    width: 76px;
    height: 30px;
    line-height: 0;
    background: #f1f2f2;
    color: #a8aaae;
    border: none;
    margin-bottom: 4px;
  }
  .active {
    background: #e63b63;
    color: #fff;
  }
}
.btn_txt {
  font-size: 15px;
  letter-spacing: 1px;
}
.btn_yes {
  font-weight: 600;
  color: #9b4669;
}
.btn_no {
  color: #a8aaae;
}
.btn_icon_img {
  width: 27px;
  height: 27px;
  // margin-left: 10px;
  margin: 0px 5px 5px 15px;
}
.history_css {
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  right: -35px;
}
.top_css {
  // position: absolute;
  // top: 50px;
  width: 100%;
}
.prompt_top_css_ {
  padding: 20px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prompt_text {
  font-weight: 600;
  color: #9b4669;
}
.bottom_css {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.data_css {
  //   background: white;
  padding: 10px 20px;
  margin: 10px 0;
}
.top_list_css {
  // margin-top: 180px;
  height: calc(100vh - 245px);
  overflow-x: hidden;
  overflow-y: scroll;
}
/deep/ .el-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom_list_css {
  margin-bottom: 180px;
  padding: 0 !important;
}
#myDiv::-webkit-scrollbar {
  // display: none;
}
#myDiv::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
#myDiv::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius: 5px;
  background-color: #cac6c6;
}
#myDiv::-webkit-scrollbar-track {
  //轨道部分
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #f9f0f4;
  border-radius: 5px;
}
#myDiv {
  height: calc(100vh - 295px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.tit_txt {
  color: #9b4669;
  font-size: 16px;
  margin-bottom: 10px;
}
.identify_li_css {
  // box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  background: white;
  //   padding: 10px 0 10px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  /deep/ .van-swipe-cell {
    width: 100%;
  }
  /deep/ .van-swipe-cell__wrapper {
    width: 100%;
  }
}
.li_css {
  padding: 10px;
  display: flex;
  align-items: center;
}

.li_box {
  width: calc(100% - 75px);
  .el-tag {
    background: #9b4669;
    color: white;
    text-align: center;
    min-width: 55px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .li_box_txt {
    font-weight: 450;
    // line-height: 25px;
    margin-top: 5px;
    color: #353535;
    letter-spacing: 0.5px;
    white-space: nowrap; //文本强制不换行；
    text-overflow: ellipsis; //文本溢出显示省略号；
    overflow: hidden; //溢出的部分隐藏；
  }
}

.checkbox_col {
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #9b4669;
    border-color: #9b4669;
  }
  /deep/ .el-checkbox__inner:hover {
    border-color: #9b4669;
  }
}
.li_btn {
  display: flex;
  align-items: center;
}
.right_css {
  display: flex;
  height: 100%;
  .right_btn {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: white;
    letter-spacing: 1px;
  }
}
.footer_submit {
  display: flex;
  justify-content: space-between;
  z-index: 999;

  align-items: center;
  padding: 15px 0;
}

.clear_result {
  text-align: center;
  width: 25%;
  padding: 10px 0px;
  box-sizing: border-box;
  color: #fff;
  background: #fa9a99;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}
.sumbit_selected {
  padding: 10px 0px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  background: #b14a75;
  color: #fff;
  border-radius: 5px;
  width: 40%;
  text-align: center;
}

.merge_result {
  color: #b14a75;
  border: #b14a75 solid 1px;
  border-radius: 5px;
  width: 25%;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/deep/.van-dialog__confirm .van-button__text {
  color: #b14a75 !important;
}
.edit_ruleForm_css {
  padding: 20px;
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}
/deep/ .el-textarea__inner {
  border-color: #c0c4cc !important;
}
/deep/.el-input__inner {
  border-color: #c0c4cc !important;
}
.identify_li_s {
  height: calc(100vh - 360px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.remark_box_css {
  padding: 20px;
}
.remark_tag_s {
  .el-tag {
    color: #7b7777;
    margin-right: 10px;
  }
}
.message_li_css {
  display: flex;
}
.msg_txt_ {
  max-width: calc(100% - 50px);
}
.msg_txt_css {
  padding: 10px;
  max-width: 100%;
  display: inline-block;
  border-radius: 5px;
  // background: white;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 16px;
}
.msg_tit {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  //   margin: 5px 0;
  line-height: 25px;
  margin-bottom: 5px;
}
.msg_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 150px);
  // background: white;
  position: relative;
  // padding: 0 10px;
  border-radius: 3px;
  color: #b14a75;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: -3px;
  .css_type {
    // width: 100%;
    text-align: center;
    margin-right: 5px;
  }
}
.public_txt_handoff {
  color: #b14a75;
  font-weight: 600;
  cursor: pointer;
}
.li_text_css {
  line-height: 20px;
  margin: 5px 0;
}
.tip_css {
  font-size: 12px;
  // position: relative;
  // top: -5px;
}
.down_css,
.up_css {
  height: 30px;
  position: relative;
  top: 10px;
}
.wenxian_li_s {
  .wenxian_li {
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
    white-space: normal;
    .li_tit {
      font-weight: 600;
      color: rgb(41, 41, 41);
    }
  }
}
.wenxian_btn {
  margin: auto;
  text-align: center;
  color: white;
  // width: 50%;
  width: 300px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  font-size: 13px;
  cursor: pointer;
}
.cite_css {
  border-left: solid 1px #bdb8b8;
  padding-left: 10px;
  margin-left: 3px;
  font-size: 12px !important;
  margin-bottom: 5px;
  position: relative;
  .wenxian_li {
    line-height: 20px !important;
  }
  .quotbox {
    background: #e8e8e9;
    padding: 6px;
    margin-right: 12px;
    border-radius: 4px;
  }
}
.cite_icon {
  position: absolute;
  right: -5px;
  top: -3px;
  font-weight: 600;
  cursor: pointer;
}
.identify_btn_css {
  background-color: #9b4669;
  padding: 6px 10px;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  margin-left: 10px;
}
.time_css {
  text-align: center;
  color: #636363;
  font-size: 12px;
  margin: 5px;
}
.typing-caret {
  // position: absolute;
  // right: 0;
  // top: 0;
  // width: 10px;
  display: inline-block;
  // height: 1.2em;
  // background-color: rgb(112, 111, 111);
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.wenxian_txt_css {
  display: inline-block;
  width: calc(100% - 10px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.input_css_pc {
  display: flex;
  // flex-direction: column;
  justify-content: flex-end;
  background: white;
  padding: 0px 0;
  // align-items: center;
}
.textarea_css {
  /deep/ .el-textarea__inner {
    // height: 100px !important;
    border: none;
    margin: 10px 0px 28px 0 !important;
    // max-height: 8em; /* 根据 maxRows 的行高调整，1 行大约 2em */
    // overflow: hidden; /* 隐藏滚动条 */
    resize: none; /* 禁用拖拽调整大小 */
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar {
    width: 5px;
    height: 1px;
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar-thumb {
    //滑块部分
    border-radius: 5px;
    background-color: #b1b0b0;
  }
  /deep/ .el-textarea__inner::-webkit-scrollbar-track {
    //轨道部分
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    border-radius: 5px;
  }
}
.tab_tag_ {
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: -10px;
}
.tab_tag_s {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: var(---8, 8px);
  background: #fff;
  .tab_tag {
    display: flex;
    height: 28px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    color: #a8aaae;
    cursor: pointer;
  }
  .tag_picked_css {
    // background: #4267ff;
    // color: #ffffff;
    background-color: #ffe9ef;
    color: #e2426b;
  }
}
.retrieval_css {
  width: 100% !important;
  /deep/ .top {
    background: none !important;
    height: 350px;
  }
  /deep/ .el-textarea__inner {
    background: #fff !important;
  }
}
.dialog_file_css {
  border-radius: var(---8, 8px) !important;
  .el-dialog__header {
    padding: 24px 16px 0px 24px;
  }
  .el-dialog__title {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
  }

  .el-dialog__body {
    padding: 24px 24px 0px 24px;
  }
  .el-dialog__footer {
    padding: 24px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  .el-radio__input.is-checked .el-radio__inner {
    color: #e2426b;
    background: #e2426b;
  }
  .el-radio__label {
    color: var(---, #8d8f94);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: var(---, #262729);
  }
  .el-form-item {
    // gap: 24px;
    margin: 0;
  }
}
.link_css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.link_url_css {
  width: 100%;
}
.link_url {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  i {
    display: inline-block;
    cursor: pointer;
  }
  line-height: 24px;
  .url_tit {
    color: var(---, #6b6e73);
  }
  .inputText {
    padding: 0px 24px;
    border-radius: 5px;
    height: 24px;
    color: var(---600, #3c68f8);
    flex: 1 0 0;
    span {
      margin: 0 0px 0 0;
      text-decoration: underline;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
.operate_txt {
  color: #c8c8c8;
  // margin: 0 20px 0 0;
  position: absolute;
  bottom: 10px;
  left: -220px;
}
</style>
  <style>
.code_css {
  z-index: 5000 !important;
}
</style>