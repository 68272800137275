<template>
  <div>
    <div class="textareaBox">
      <el-input
        type="textarea"
        :rows="4"
        :autosize="{ minRows: 8, maxRows: 8 }"
        :placeholder="`可批量查询文献，每条需求以换行结束，需求示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`"
        v-model="textarea"
      >
      </el-input>
      <div class="uploadBox">
        <el-tooltip class="item" effect="dark" content="图片识别" placement="top">
          <el-upload
            class="upload-demo"
            ref="upload"
            name="img"
            :action="action_url"
            :auto-upload="true"
            list-type="picture"
            accept=".jpg,.png,.gif,.jpeg"
            :file-list="fileList"
            :headers="headers"
            :show-file-list="false"
            :on-success="succesfile"
          >
          <img class="icon" src="../../../assets/image/upload.png" alt="" />
          </el-upload>
        </el-tooltip>
      </div>
    </div>
    <div class="btnBox">
      <el-button type="primary" :loading="loading" @click="identifyHandle">识 别</el-button>
    </div>
    <div class="notesBox" v-bind:style="{ minHeight: Height + 'px' }">
      <div
        v-if="!identify"
        v-bind:style="{ minHeight: Height + 'px' }"
        style="display: flex; align-items: center"
      >
        <div class="note_fu" style="width: 100%">
          <div class="note_fu01">
            <el-divider content-position="left">注意事项</el-divider>
            <div class="note">
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >建议每次提交需求有明显的标识符，例如：PMID：*****
                  ISBN：****** ，有明显的标识符，会大大提高识别的准确率</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >如果文献类型是"其他"，请您首先确定"需求内容"的准确性和完整性，如果没有问题，请修改正确的"文献类型"这样也会大大提高识别的准确率</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >图片上传支持jpg、png、gif、jpeg，{注意：请保证图片的清晰度，不然会识别有误}</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >如果{识别的文章有误}或者需要{修改识别的信息}，请选择选择"编辑"进行修改，手机端需要在识别以后左滑呼出“编辑”功能</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >注意：1.{如果需要文章的"其他信息"请先点击"识别"}，2.{然后请点击"备注"添加其他的信息}，其他信息是指：补充材料、正式版等</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-table
          ref="multipleTable"
          tooltip-effect="dark"
          :row-class-name="tableRowClassName"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @select="selectHandle"
          height="calc(100vh - 580px)"
        >
          <el-table-column type="selection" :selectable="selectable" width="40"></el-table-column>
          <el-table-column label="序号" width="80" type="index"></el-table-column>
          <el-table-column label="提交状态" width="160">
            <template slot-scope="scope">
              <span :style="{ color: submitType[scope.row.submit].color }">
                {{ submitType[scope.row.submit].text }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template slot="header" slot-scope="scope">
              <span style="margin-right: 10px; display: inline-block"
                >文献类型</span
              >
              <el-popover placement="top" width="200" trigger="hover">
                <p style="line-height: 20px">
                  <span>注意: </span>
                  <span style="color: #f86b52">
                    若文献类型识别为中文书或英文书,则该文献类型不能进行修改
                  </span>
                </p>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-dropdown
                size="mini"
                split-button
                type="primary"
                :disabled="scope.row.disabled"
                :class="scope.row.code"
              >
                <span>{{ scope.row.name }}</span>
                <el-dropdown-menu slot="dropdown">
                  <div v-if="!scope.row.disabled">
                    <el-dropdown-item
                      v-for="(item, key, index) in codeType"
                      :key="index"
                      @click.native="codeChange(scope.row.index, key, item.text)"
                    >
                      <span v-if="!scope.row.disabled">
                        {{ item.text }}
                      </span>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
              <el-tag
                type="success"
                slot="reference"
                slot-scope="scope"
                v-if="scope.row.code !== 'zhBook'"
              >
                中文书
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="需求内容">
            <template slot-scope="scope">
              <div v-if="editIndex === scope.$index && editType === 'text'" class="editContent">
                <el-input v-model="editText" placeholder="请输入需求内容" style="width: 85%"></el-input>
                <i class="el-icon-success" @click="confirmHandle"></i>
              </div>
              <div v-else class="editContent">
                <span style="margin-right: 10px">{{ scope.row.text }}</span>
                <i @click="editChange(scope.row, scope.$index, 'text')" class="el-icon-edit"></i>
              </div>    
            </template>
          </el-table-column>
          <el-table-column label="备注" width="300">
            <template slot-scope="scope">
              <div v-if="editIndex === scope.$index && editType === 'remarks'" class="editContent">
                <el-input v-model="editText" placeholder="请输入备注内容" style="width: 85%"></el-input>
                <i class="el-icon-success" @click="confirmHandle"></i>
              </div>
              <div v-else class="editContent">
                <span style="margin-right: 10px">{{ scope.row.remarks || '添加' }}</span>
                <i @click="editChange(scope.row, scope.$index, 'remarks')" class="el-icon-edit"></i>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="editHandle(scope.row, scope.$index)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableData.length > 0" class="operateBtnBox">
          <el-button type="danger" @click="clearHandle">清空结果</el-button>
          <el-button type="primary" plain @click="mergeHandle">需求合并</el-button>
          <el-button type="primary" :loading="submitLoading" @click="submitHandle">提 交</el-button>
        </div>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="需求类型">
          <el-select
            v-model="form.code"
            placeholder="请选择需求类型"
            style="width: 100%"
            :disabled="dialogEditType"
            @change="optionChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求内容">
          <el-input v-model="form.text" placeholder="请输入需求内容"></el-input>
        </el-form-item>
        <el-form-item label="添加备注">
          <el-input type="textarea" v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { newSortPost, newDemandPost } from '@/Api/manage'

export default {
  data() {
    return {
      headers: {
        token: localStorage.getItem("token"),
      },
      fileList: [],
      textarea: '', // 输入框的值
      Height: 0, // 高度
      identify: false, // 是否识别
      tableData: [], // 识别的表格数据
      submitType: {
        0: { text: '未提交', color: '#909399' },
        1: { text: '提交成功', color: '#409eff' },
        2: { text: '重复提交', color: '#e6a23c' },
        3: { text: '今日非中文书已达上限', color: '#f56c6c' },
        4: { text: '今日中文书已达上限', color: '#f56c6c' },
        5: { text: '提交失败', color: '#f56c6c' },
        6: { text: '需求不能为空', color: '#f56c6c' },
        7: { text: '提交数量已达上限', color: '#f56c6c' },
      },
      codeType: {
        zhBook: { text: '中文书', color: '#d9a673' },
        enBook: { text: '英文书', color: '#d9d973' },
        PMID: { text: 'PMID', color: '#73d9a6' },
        PMC: { text: 'PMC', color: '#73d973' },
        DOI: { text: 'DOI', color: '#8676bd' },
        Url: { text: '链接地址', color: '#73d9d9' },
        zhDoc: { text: '中文文献', color: '#73a6d9' },
        enDoc: { text: '英文文献', color: '#7373d9' },
        patent: { text: '专利', color: '#a673d9' },
        standard: { text: '标准', color: '#d973d9' },
        annex: { text: '附件', color: '#73d8d9' },
        negative: { text: '负面消息', color: '#AC2909' },
      },
      multipleSelection: [], // 多选
      editIndex: null, // 编辑行的索引
      editText: '', // 编辑行的文本
      editType: '', // 编辑行的类型
      loading: false, // 加载状态
      options: [
        { value: "zhBook", label: "中文书" },
        { value: "enBook", label: "英文书" },
        { value: "PMID", label: "PMID" },
        { value: "PMC", label: "PMC" },
        { value: "Doi", label: "DOI" },
        { value: "Url", label: "链接地址" },
        { value: "zhDoc", label: "中文文献" },
        { value: "enDoc", label: "英文文献" },
        { value: "patent", label: "专利" },
        { value: "standard", label: "标准" },
      ],
      form: {
        code: '', // 需求类型
        name: '', // 需求类型名称
        text: '', // 需求内容
        remarks: '' // 备注
      },
      dialogVisible: false, // 编辑内容弹窗
      dialogTitle: '', // 编辑弹窗标题
      dialogEditIndex: null, // 编辑行的索引
      dialogEditType: null, // 编辑行的类型
      submitLoading: false, // 提交加载状态
      isMerge: false, // 是否合并
      action_url: process.env.VUE_APP_API_BASE_URL + "literature/ImageOcr/",
    }
  },
  mounted() {
    this.Height = document.documentElement.clientHeight - 540; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 540;
    }
  },
  methods: {
    // 新的识别需求
    async identifyHandle() {
      if(this.textarea === '') {
        return this.$message.warning('请输入识别内容')
      }
      this.editIndex = null
      this.editText = ''
      this.editType = ''
      this.loading = true
      let res = await newSortPost({ text: this.textarea })
      if(res.data.error_code === 0) {
        this.identify = true
        res.data.data.forEach(item => {
          item.submit = 0
          item.disabled = false
          if(item.code === 'zhBook' || item.code === 'enBook') {
            item.disabled = true
          }
        })
        this.tableData = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
      this.loading = false
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 多选  批量操作
    handleSelectionChange(val) {
      if(val.length > 10) {
        this.$message.warning('单次最多提交10条需求！')
        val.splice(10)
      }
      this.multipleSelection = val
    },
    // 选中行
    selectHandle(val) {
      this.multipleSelection = val
    },
    // 判断选中状态
    selectable(row) {
      if (row.submit !== 1 && row.submit !== 2) {
        return true;
      } else {
        return false;
      }
    },
    // 修改文献类型
    codeChange(index, key, text) {
      this.tableData[index].code = key;
      this.tableData[index].name = text;
    },
    // 编辑需求/备注
    editChange(row, index, key) {
      this.editIndex = index;
      this.editType = key;
      this.editText = row[key];
    },
    // 确认编辑
    confirmHandle() {
      if(this.editType === 'text' && this.editText === '') {
        return this.$message.warning('请输入需求内容')
      }
      this.tableData[this.editIndex][this.editType] = this.editText;
      this.editIndex = null;
    },
    // 批量编辑弹窗
    editHandle(row, index) {
      this.dialogTitle = '编辑需求'
      this.dialogEditIndex = index
      this.dialogEditType = row.disabled
      this.form.code = row.code
      this.form.name = row.name
      this.form.text = row.text
      this.form.remarks = row.remarks
      this.dialogVisible = true
    },
    // 需求类型改变
    optionChange(val) {
      this.form.name = this.codeType[val].text
    },
    // 关闭批量编辑弹窗
    handleClose() {
      this.dialogVisible = false
      this.dialogTitle = ''
      this.form = {
        code: '', // 需求类型
        name: '', // 需求类型名称
        text: '', // 需求内容
        remarks: '' // 备注
      }
    },
    // 确认批量编辑
    handleConfirm() {
      // 合并需求
      if(this.isMerge) {
        let obj = JSON.parse(JSON.stringify(this.form))
        this.multipleSelection.forEach((item, index) => {
          if(index === 0) {
            this.tableData.splice(item.index, 1, {
              code: obj.code,
              name: obj.name,
              text: obj.text,
              remarks: obj.remarks,
              index: item.index,
              submit: 0
            })
          } else {
            this.tableData.splice(item.index, 1)
          }
        })
        this.dialogVisible = false
        this.dialogTitle = ''
        this.isMerge = false
        this.form = {
          code: '', // 需求类型
          name: '', // 需求类型名称
          text: '', // 需求内容
          remarks: '' // 备注
        }
        this.multipleSelection = []
        return
      }
      this.tableData[this.dialogEditIndex].code = this.form.code
      this.tableData[this.dialogEditIndex].name = this.form.name
      this.tableData[this.dialogEditIndex].text = this.form.text
      this.tableData[this.dialogEditIndex].remarks = this.form.remarks
      this.dialogVisible = false
      this.form = {
        code: '', // 需求类型
        name: '', // 需求类型名称
        text: '', // 需求内容
        remarks: '' // 备注
      }
      this.dialogEditIndex = null
    },
    // 清空结果
    clearHandle() {
      this.tableData = []
      this.editIndex = null
      this.editText = ''
      this.editType = ''
      this.multipleSelection = []
    },
    // 提交
    async submitHandle() {
      if(this.multipleSelection.length === 0) {
        return this.$message.warning('请勾选需求任务')
      }
      this.submitLoading = true
      let demandArr = []
      this.multipleSelection.forEach(item => {
        demandArr.push({
          code: item.code,
          name: item.name,
          text: item.text,
          remarks: item.remarks
        })
      })
      let res = await newDemandPost({
        demand: demandArr
      })
      this.submitLoading = false
      if(res.data.error_code === 0) {
        this.$message.success(res.data.msg)
        setTimeout(() => {
          this.$emit('submitHandle')
        }, 1000)
      } else {
        this.$message.error(res.data.msg)
      }
    },
    // 合并需求
    mergeHandle() {
      if(this.multipleSelection.length < 2) {
        return this.$message.warning('请至少勾选2项需求任务')
      }
      this.isMerge = true
      this.dialogTitle = '合并需求'
      this.multipleSelection.forEach((item, index) => {
        if(index === 0) {
          this.form.text = item.text
          this.form.remarks = item.remarks
          this.form.code = item.code
          this.form.name = item.name
          this.dialogEditType = item.code
        } else {
          this.form.text += item.text
          this.form.remarks += item.remarks
        }
      })
      this.dialogVisible = true
    },
    //ocr 识别回调
    async succesfile(res) {
      if(res.error_code === 0) {
        if(res.data[1].length == 0 || res.data[1] == null) {
          this.$message.error('识别失败，请重新上传')
        } else {
          this.textarea = ''
          res.data[1].forEach(item => {
            this.textarea += item.words + '\n'
          })
          this.identifyHandle()
        }
      } else {
        this.$message.error(res.msg || '识别失败，请重新上传')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.textareaBox {
  margin: 64px 25% 20px 25%;
  position: relative;
  ::v-deep .el-textarea__inner {
    border: none;
    padding: 10px;
    resize: none;
  }
  .uploadBox {
    position: absolute;
    right: 16px;
    bottom: 10px;
    i {
      color: #B14A75;
      font-size: 32px;
      cursor: pointer;
    }
  }
}
.btnBox {
  margin-top: 42px;
  display: flex;
  justify-content: center;
  .el-button {
    width: 180px;
  }
}
.notesBox {
  margin-top: 32px;
  background-color: #fff;
  padding: 0 10%;
  .note_fu {
    display: flex;
    justify-content: center;
    margin: 20px 110px 20px 80px;
    .note_fu01 {
      width: 100%;
      ::v-deep .el-divider {
        margin: 0 !important;
      }
      .note {
        padding: 10px 30px;
        border-left: 1px #dcdfe6 solid;
        border-right: 1px #dcdfe6 solid;
        border-bottom: 1px #dcdfe6 solid;
        p {
          line-height: 25px;
          display: flex;
          margin: 10px 0;
          img {
            margin: 0 20px;
            width: 12px;
            height: 12px;
            position: relative;
            top: 8px;
          }
          span {
            word-break: normal;
            display: inline-block;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow: hidden;
            .col {
              position: relative;
              top: 7px;
            }
            .top {
              font-size: 5px;
              position: relative;
              top: -8px;
              left: -3px;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}

.standard {
  /deep/ .el-button-group {
    .el-button {
      background: #d973d9 !important;
      border: none;
    }
  }
}
.patent {
  /deep/ .el-button-group {
    .el-button {
      background: #a673d9 !important;
      border: none;
    }
  }
}
.enBook {
  /deep/ .el-button-group {
    .el-button {
      background: #d9d973 !important;
      border: none;
    }
  }
}
.PMC {
  /deep/ .el-button-group {
    .el-button {
      background: #73d9a6 !important;
      border: none;
    }
  }
}
.PMID {
  /deep/ .el-button-group {
    .el-button {
      background: #73d973 !important;
      border: none;
    }
  }
}
.Url {
  /deep/ .el-button-group {
    .el-button {
      background: #73d9d9 !important;
      border: none;
    }
  }
}
.DOI {
  /deep/ .el-button-group {
    .el-button {
      background: #8676bd !important;
      border: none;
    }
  }
}
.enDoc {
  /deep/ .el-button-group {
    .el-button {
      background: #7373d9 !important;
      border: none;
    }
  }
}
.zhDoc {
  /deep/ .el-button-group {
    .el-button {
      background: #73a6d9 !important;
      border: none;
    }
  }
}
.zhBook {
  /deep/ .el-button-group {
    .el-button {
      background: #d9a673 !important;
      border: none;
    }
  }
}
.annex {
  /deep/ .el-button-group {
    .el-button {
      background: #73d8d9 !important;
      border: none;
    }
  }
}
.negative {
  /deep/ .el-button-group {
    .el-button {
      background: #AC2909 !important;
      border: none;
    }
  }
}
.editContent {
  i {
    cursor: pointer;
  }
  ::v-deep .el-icon-success {
    margin-left: 10px;
    color: #73D973;
    font-size: 18px;
  }
}
.operateBtnBox {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  .el-button {
    margin: 0 10px;
  }
}
</style>