<template>
  <div class="management_box" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="top_css">
      <div class="header">
        <!-- <h2>资源管理：</h2> -->
        <div class="input">
          <el-input
            prefix-icon="el-icon-search"
            v-model="searchInput"
            placeholder="请输入检索字段"
            clearable
          ></el-input>
          <el-button type="primary" round @click="onSubmit">搜索</el-button>
        </div>
      </div>
      <div class="search_field">
        <div class="sifts">
          <div class="sifts_tit">文献类型</div>
          <div class="choose">
            <el-checkbox-group
              v-model="Types"
              @change="search_type_"
              class="checkbox_group_css"
            >
              <el-checkbox
                v-for="(item, index) in type"
                :key="index"
                :label="item.type"
                >{{ item.book_type }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">全部状态</div>
          <div class="choose">
            <el-checkbox-group
              v-model="condition"
              @change="search_states_"
              class="checkbox_group_css"
            >
              <el-checkbox
                v-for="(item, index) in states"
                :key="index"
                :label="item.status"
                >{{ item.task }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>

        <div class="sifts" style="align-items: center">
          <div class="sifts_tit">检索时间</div>
          <div class="choose" style="display: flex">
            <el-date-picker
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              :clearable="false"
              @change="changeDate"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">是否已读</div>
          <div class="choose" style="display: flex">
            <el-radio-group v-model="radio" @change="radios(radio)">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="2">已读</el-radio>
              <el-radio :label="1">未读</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="sifts">
          <div class="sifts_tit">需求来源</div>
          <div class="choose" style="display: flex">
            <el-radio-group v-model="origin" @change="origins(origin)">
              <el-radio :label="0">全部</el-radio>
              <el-radio label="QQ">QQ群</el-radio>
              <el-radio label="Wechat">微信群</el-radio>
              <el-radio label="Applet">小程序&PC</el-radio>
              <el-radio label="ServiceNumber">服务号</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="sifts">
          <div class="choose" style="display: flex">
            <el-button size="mini" round @click="reset" class="reset_css"
              >重置</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="tables">
      <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ fontSize: '14px', color: '#606266' }"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :cell-style="getCellStyle"
        :cell-class-name="getCellClassName"
      >
        <el-table-column type="selection" width="35" align="center">
          <!-- <div class="read_css"></div> -->
        </el-table-column>

        <el-table-column label="时间" align="center" width="170">
          <template slot="header">
            <el-dropdown @command="handleCommand">
              <span
                class="el-dropdown-link"
                style="
                  color: var(--Gray-Gray-500, #a1a5b7);
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
              >
                时间排序
                <img
                  src="../../../assets/imgs/2.2/descending.png"
                  style="margin: 0 0 0 8px"
                  alt=""
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="beforeHandleCommand('time_desc')"
                  >时间降序</el-dropdown-item
                >
                <el-dropdown-item :command="beforeHandleCommand('time_asc')"
                  >时间升序</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            {{ scope.row.create_time | parseTime("{y}-{m}-{d} {h}:{i}:{s}") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="text"
          label="需求内容"
          align="left"
          min-width="400px"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-popover
                placement="bottom-start"
                width="300"
                trigger="hover"
                class="popovers"
              >
                <p style="margin: 0 0 5px 0">
                  <span>需求类型：</span>
                  <span
                    :style="{ color: codeType[scope.row.demand_code].color }"
                  >
                    {{ codeType[scope.row.demand_code].text }}
                  </span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>需求内容：</span><span>{{ scope.row.original }}</span>
                </p>
                <p style="margin: 0 0 5px 0">
                  <span>识别内容：</span>
                  <span v-html="scope.row.content"></span>
                </p>

                <span slot="reference" class="oneRow" style="cursor: pointer">
                  <el-tag size="mini">
                    {{ codeType[scope.row.demand_code].text }}
                  </el-tag>
                  <el-tag
                    size="mini"
                    style="
                      background: #ffe9ef !important;
                      color: #e2426b !important;
                      border-color: #ffe9ef !important;
                    "
                  >
                    {{ sourceType[scope.row.source] }}
                  </el-tag>
                  <el-tag
                    v-if="scope.row.file_extension"
                    size="mini"
                    round
                    style="
                      background: var(--Gray-100, #f5f5f4) !important;
                      color: var(--Gray-500, #79716b) !important;
                      border-color: var(--Gray-100, #f5f5f4) !important;
                    "
                    >{{ scope.row.file_extension }}</el-tag
                  >
                  <span class="row_text_css">
                    {{ scope.row.original }}
                  </span>

                  <i class="el-icon-warning-outline"></i>
                </span>
              </el-popover>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="处理状态" align="center" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.isefficacy">
              <div
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
              >
                已失效
              </div>
            </div>
            <div v-else>
              <div style="display: flex; justify-content: center">
                <div
                  v-if="
                    scope.row.status == 4 && scope.row.short_link.length > 0
                  "
                  style="
                    color: var(---600, #00b049);
                    background: var(--Green-50, #ecfdf3);
                  "
                  class="status_btn_css"
                >
                  处理完成
                </div>
                <div
                  style="
                    color: var(--Blue-500, #2e90fa);
                    background: var(--Blue-50, #eff8ff);
                  "
                  class="status_btn_css"
                  v-if="
                    scope.row.status == 2 ||
                    (scope.row.status == 4 && scope.row.short_link.length == 0)
                  "
                >
                  {{
                    scope.row.artificial_status === 2
                      ? "二次处理中"
                      : "正在处理"
                  }}
                </div>
                <div
                  style="
                    color: var(--Yellow-500, #f79009);
                    background: var(--Yellow-50, #fffaeb);
                  "
                  class="status_btn_css"
                  v-if="scope.row.status == 1"
                >
                  待处理
                </div>
                <div
                  style="
                    cursor: auto;
                    color: var(---500, #eb7272);
                    background: var(---Color-7, #fff);
                    cursor: pointer;
                  "
                  class="status_btn_css"
                  v-if="scope.row.status == 3"
                  @click="links(scope.row)"
                >
                  处理失败
                </div>
              </div>
              <div
                v-if="scope.row.status == 5"
                style="color: #e13edb; background: #fff2fd"
                class="status_btn_css"
              >
                相似推荐
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content" style="line-height: 22px">
                    没有找到电子版资源，点击“查找相 <br />
                    似”由AI智能推荐相似资源。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="相似文献" align="center" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="operate_btn"
              style=""
              v-if="
                (scope.row.status == 3 ||
                (scope.row.status == 4 && scope.row.short_link.length > 0) ||
                scope.row.status == 5) && scope.row.demand_code !== 'zhBook'
              "
              @click="mores(scope.row)"
              >{{
                scope.row.similar_url &&
                scope.row.status == 4 &&
                scope.row.demand_code == "zhBook"
                  ? "相关图书"
                  : "查找相似"
              }}</el-button
            ><el-button
              size="mini"
              class="operate_btn"
              style=""
              v-if="
               scope.row.similar_url && scope.row.demand_code == 'zhBook'
              "
              @click="mores(scope.row)"
              >
              相关图书
              <!-- {{
                scope.row.similar_url &&
                scope.row.status == 4 &&
                scope.row.demand_code == "zhBook"
                  ? "相关图书"
                  : "查找相似"
              }} -->
              </el-button
            ></template
          ></el-table-column
        >
        <el-table-column label="文件下载" align="center" width="180">
          <template slot-scope="scope">
            <div
              class="pdfdown_css"
              v-if="
                scope.row.down_file &&
                scope.row.down_file.length > 0 &&
                !scope.row.isefficacy
              "
            >
              <div class="file_s">
                <span
                  v-for="(item, index) in scope.row.down_file"
                  :key="index"
                  :style="{ display: index < 3 ? 'block' : 'none' }"
                >
                  <span
                    v-if="index < 3"
                    class="down_file_css"
                    @click="downFileOpen(item, scope.row, index)"
                  >
                    <span v-if="index < 2">
                      链接<span v-if="index == 0">一</span
                      ><span v-else>二</span>
                    </span>
                    <span v-else> 更多 </span>
                  </span>
                </span>
              </div>
              <span
                v-if="scope.row.status == 4 && scope.row.zhbook_pass && scope.row.short_link.length > 0"
                style="font-size: 12px; color: #8d8f94"
                >链接密码：{{ scope.row.zhbook_pass }}</span
              >
            </div>
            <span v-else style="color: #8d8f94">-</span>
          </template>
        </el-table-column>
        <el-table-column label="双语翻译状态" align="center" width="130">
          <template slot="header">
            <span style="margin: 0 5px 0 0">双语翻译状态</span>
            <el-popover
              placement="bottom"
              width="280"
              trigger="hover"
              content="仅支持PDF格式的英文文献翻译成中文"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.yicai_task_id && !scope.row.isefficacy">
              <div
                style="
                  cursor: auto;
                  color: var(---500, #eb7272);
                  background: var(---Color-7, #fff);
                "
                class="status_btn_css"
                v-if="
                  scope.row.yicai_status == 2 || scope.row.yicai_status == 3
                "
                @click="links(scope.row)"
              >
                翻译失败
              </div>
              <div
                v-else-if="scope.row.yicai_status == 0"
                style="
                  color: var(--Blue-500, #2e90fa);
                  background: var(--Blue-50, #eff8ff);
                "
                class="status_btn_css"
              >
                翻译中
              </div>
              <div
                v-else-if="scope.row.yicai_status == 1"
                style="
                  color: var(---600, #00b049);
                  background: var(--Green-50, #ecfdf3);
                "
                class="status_btn_css"
              >
                翻译完成
              </div>
              <span v-else style="color: #8d8f94">-</span>
            </div>
            <span v-else style="color: #8d8f94">-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="left"
          fixed="right"
          width="180"
          header-align="center"
        >
          <template slot-scope="scope">
            <div class="btns">
              <div
                size="mini"
                class="operate_btn"
                v-if="
                  !scope.row.isefficacy &&
                  scope.row.file_extension &&
                  scope.row.file_extension == '.PDF' &&
                  !scope.row.yicai_task_id &&
                  (scope.row.demand_code == 'enDoc' ||
                    scope.row.demand_code == 'PMID' ||
                    scope.row.demand_code == 'PMC' ||
                    scope.row.demand_code == 'DOI')
                "
                @click="translationSub(scope.row)"
              >
                <span v-if="scope.row.sub_or_id == scope.row.id">提交中</span>
                <span v-else>提交翻译</span>
                <i
                  v-if="scope.row.sub_or_id == scope.row.id"
                  class="el-icon-loading"
                ></i>
                <img
                  class="new_icon_css"
                  v-if="scope.row.sub_or_id !== scope.row.id"
                  src="../../../assets/imgs/2.2/new_2x.png"
                  alt=""
                />
              </div>
              <div
                class="cascader_css"
                v-if="
                  scope.row.yicai_task_id &&
                  scope.row.yicai_status == 1 &&
                  !scope.row.isefficacy
                "
              >
                <el-cascader
                  v-model="cascaderValue"
                  :options="typeOptions"
                  placeholder="请选择下载类型"
                  @change="handleDownloadType($event, scope.row, scope.$index)"
                  size="mini"
                  class="z-index"
                  :key="scope.$index"
                  ref="cascader"
                  popper-class="train-tree"
                  placement="bottom-end"
                ></el-cascader>
                <el-button size="mini" class="operate_btn pdf_xiazai"
                  >查看翻译结果
                </el-button>
              </div>
              <el-button
                size="mini"
                class="operate_btn"
                @click="translationRealTime(scope.row)"
                v-if="
                  scope.row.yicai_task_id &&
                  scope.row.yicai_status == 0 &&
                  !scope.row.isefficacy
                "
                >在线实时翻译
                <el-popover
                  placement="top-end"
                  title=""
                  width="300"
                  trigger="hover"
                >
                  <p>
                    在线实时翻译，点击即可预览翻译结果， 推荐使用 Google
                    Chrome、Microsoft Edge 或 360 极速浏览器查看。
                  </p>
                  <p style="margin: 7px 0 7px 0">
                    如板式存在问题，可等待翻译任务完成后查看文档翻译的最终结果。
                  </p>
                  <p>在线实时翻译不会额外消耗翻译次数。</p>

                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </el-button>

              <el-dropdown
                @command="operateHandleCommand"
                :data-row="scope.row"
              >
                <!-- && scope.row.status !== -3 -->
                <span class="el-dropdown-link operate_btn">
                  更多
                  <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="scope.row.status == 4 && !scope.row.isefficacy"
                    :command="
                      operateCommand(scope.$index, scope.row, 'feedback')
                    "
                    >错误反馈</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.status == 3 && !scope.row.isefficacy"
                    :command="
                      operateCommand(scope.$index, scope.row, 'causeFail')
                    "
                    >查看失败原因</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.isefficacy"
                    :command="
                      operateCommand(scope.$index, scope.row, 'resubmit')
                    "
                    >重新提交</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="operateCommand(scope.$index, scope.row, 'del')"
                    divided
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="imgs">
            <img
              src="../../../assets/imgs/zanwushuj.png"
              alt=""
              style="width: 50%"
            />
          </div>
        </template>
      </el-table>
    </div>
    <el-dialog
      title="相似推荐"
      :visible.sync="dialogVisible"
      width="600px"
      class="greater"
      @close="closeDialog"
      custom-class="dialog_file_css recommend_css"
      :modal-append-to-body="true"
    >
      <div
        v-loading="loading_sub"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="prompt_txt">
          <div class="prompt_txt_css">
            由AI根据当前资源智能匹配提供相关推荐，相似资源将视为一个新的需求查找该需求会消耗文献查询量。
          </div>
        </div>
        <div class="canto_css" v-if="chBook.length > 0">
          <div class="canto_li">
            <div class="canto_tit">【标题】</div>
            <div
              class="canto_con"
              :style="
                chBook[current_page].isoa ? 'width: calc(100% - 215px);' : ''
              "
              v-html="chBook[current_page].title"
            ></div>
            <div class="isoa_css" v-if="chBook[current_page].isoa">
              <img src="../../../assets/imgs/2.2/isoa.png" alt="" />
              <span class="isoa_txt">Open Access</span>
            </div>
          </div>
          <div class="canto_li" v-if="chBook[0].isbn">
            <div class="canto_tit">【ISBN】</div>
            <div class="canto_con">
              {{ chBook[current_page].isbn }}
            </div>
          </div>
          <div class="canto_li" v-if="chBook[0].doi">
            <div class="canto_tit">【DOI】</div>
            <div class="canto_con">
              {{ chBook[current_page].doi }}
            </div>
          </div>
          <div
            class="canto_li"
            v-if="
              chBook[current_page].author &&
              chBook[current_page].author.length > 0
            "
          >
            <div class="canto_tit">【作者】</div>
            <div class="canto_con">
              {{ chBook[current_page].author.join(" ; ") }}
            </div>
          </div>
          <div class="canto_li" v-if="chBook[current_page].year">
            <div class="canto_tit">【出版时间】</div>
            <div class="canto_con">
              {{ chBook[current_page].year }}
            </div>
          </div>
          <div class="canto_li" v-if="chBook[current_page].publisher">
            <div class="canto_tit">【出版地】</div>
            <div class="canto_con">
              {{ chBook[current_page].publisher }}
            </div>
          </div>
          <div class="canto_li" v-if="chBook[current_page].abstract">
            <div class="canto_tit">【内容摘要】</div>
            <div class="canto_con" v-if="chBook[current_page].abstract">
              <span
                v-if="!isExpanded && chBook[current_page].abstract.length > 120"
              >
                {{ chBook[current_page].abstract.slice(0, 120) }}...
              </span>
              <span v-else>
                {{ chBook[current_page].abstract }}
              </span>
              <span
                class="toggle-btn"
                v-if="chBook[current_page].abstract.length > 120"
                @click="isExpanded = !isExpanded"
              >
                {{ isExpanded ? "收起" : "展开" }}
              </span>
            </div>
          </div>
        </div>
        <div class="canto_handoff" v-if="chBook.length > 0">
          <div
            class="handoff_left"
            @click="prevPage"
            :style="
              current_page == 0
                ? 'color: var(----, #AAADB5);'
                : 'cursor: pointer;'
            "
          >
            <i class="el-icon-arrow-left"></i>
            <span>上一篇</span>
          </div>
          <div class="handoff_con">
            {{ current_page + 1 }} / {{ chBook.length }}
          </div>
          <div
            class="handoff_right"
            @click="nextPage"
            :style="
              current_page + 1 == chBook.length
                ? 'color: var(----, #AAADB5);'
                : 'cursor: pointer;'
            "
          >
            <span>下一篇</span><i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div
          class="submit_btn_css"
          v-if="chBook.length > 0"
          @click="submit_similar"
        >
          <div class="submit_btn">
            {{ submit_txt }}
            <i v-if="submit_txt == '提交中'" class="el-icon-loading"></i>
          </div>
        </div>
        <div class="canto_none" v-if="chBook.length <= 0">暂无资源</div>
      </div>
    </el-dialog>
    <el-dialog
      @close="closeDialog"
      title="编辑反馈信息"
      :visible.sync="dialogMessage"
      width="580px"
      class="message"
      custom-class="dialog_file_css"
    >
      <el-form
        :model="anew"
        :rules="rules"
        ref="anew"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="反馈原因">
          <el-radio-group v-model="anew.type" class="anew_css">
            <el-radio label="链接地址无法访问">链接地址无法访问</el-radio>
            <el-radio label="需要正式版">需要正式版</el-radio>
            <el-radio label="需要补充材料">需要补充材料</el-radio>
            <el-radio label="内容不符">内容不符</el-radio>
            <el-radio label="其他">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="反馈信息"
          prop="msg"
          v-if="anew.type == '其他'"
          style="margin-top: 24px"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="anew.msg"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          style="padding: 24px; display: flex; justify-content: flex-end"
        >
          <el-button
            style="
              color: var(---, #262729);
              background: var(---300, #f1f2f2);
              border: none !important;
            "
            class="dialog_btn"
            @click="dialogMessage = false"
            >取 消</el-button
          >
          <el-button
            style="
              color: var(--white-dark-white, #fff);
              background: #e2426b;
              border: none !important;
            "
            type="primary"
            @click="Resubmit"
            class="dialog_btn"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="pag">
      <div class="bottom">
        <div class="footer">
          <el-button v-if="batchs" class="footer_btn" @click="deleBatch"
            >批量删除</el-button
          >
          <el-button
            v-if="batchs"
            class="footer_btn"
            style="background-color: #e6a23c; margin-left: 24px"
            @click="onClickDown"
            >批量导出</el-button
          >
          <div
            class="allRead_btn"
            v-if="num_total"
            style="margin-left: 24px"
            @click="allRead"
          >
            <img
              class="hover-img"
              src="../../../assets/imgs/2.2/read_no.png"
              alt=""
            />
            <span>全部标记为已读</span>
          </div>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        layout="total, prev, pager, next, jumper"
        :total="totalCount"
        v-if="totalCount > 0"
        style="padding: 3px 5px"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="链接地址"
      :visible.sync="dialogVisible01"
      :width="form.url.length > 1 ? '460px' : '460px'"
      @close="closeDialog"
      custom-class="dialog_file_css"
      :show-close="false"
    >
      <div class="link_css">
        <div
          class="link_url_css"
          v-for="(item, index) in form.url"
          :key="index"
        >
          <div class="link_url">
            <span class="url_tit"
              >{{
                form.url.length > 1
                  ? "链接" + numberToChinese(index + 1)
                  : "链接"
              }}
              <span v-if="form.url.length <= 1">&ensp;</span>
            </span>
            <span id="copy" class="inputText"
              ><span class="urlText" style="" @click="openLink01(item)">{{
                item
              }}</span></span
            >
            <img
              @click="copyLink01(item)"
              style="cursor: pointer"
              src="../../../assets/imgs/2.2/copy.png"
              alt=""
            />
          </div>
        </div>
        <div class="password_css" v-if="passType == 1">
          <span class="url_tit"> 文档密码 </span>
          <span style="width: calc(100% - 72px); padding: 0 0 0 10px">
            <span class="inputText" id="pass">{{ form.password[0] }}</span
            ><span style="color: rgb(115, 171, 223); font-size: 10px"
              >（此密码为您微信 id的后4位）</span
            >
          </span>

          <img
            @click="copyPass"
            style="cursor: pointer"
            src="../../../assets/imgs/2.2/copy.png"
            alt=""
          />
        </div>
        <!-- -->
        <div class="password_css" v-if="passType == 2">
          <span class="url_tit"> 提取码 </span>
          <span
            class="inputText"
            id="pass"
            style="width: calc(100% - 58px); padding: 0 0 0 25px"
            >{{ "form.password[0]" }}</span
          >
          <!-- <i @click="copyPass" class="el-icon-document-copy"></i> -->
          <img
            @click="copyPass"
            style="cursor: pointer"
            src="../../../assets/imgs/2.2/copy.png"
            alt=""
          />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="footer_btn" @click="dialogVisible01 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible02"
      :width="'550px'"
      @close="closeDialog"
      custom-class="dialog_file_css"
    >
      <p style="text-indent: 2em; lin-height: 20px">{{ causeFail }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="
            color: var(--white-dark-white, #fff);
            background: #e2426b;
            border: none !important;
          "
          class="dialog_btn"
          type="primary"
          @click="dialogVisible02 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="文档学术翻译"
      :visible.sync="dialogTranslation"
      width="580px"
      class="message"
      custom-class="dialog_file_css"
    >
      <p style="color: var(---, #6b6e73); line-height: 24px">
        提交此篇文献至文档学术翻译任务？这将消耗您的文档翻译次数。任务处理完成后，您可以点击“查看结果“按钮查看翻译结果。
      </p>
      <p
        style="
          padding: 24px 24px 0px 24px;
          color: #e2426b;
          line-height: 24px;
          text-align: center;
        "
      >
        剩余翻译次数：{{ remainderNumber }}次
      </p>
      <div style="padding: 24px; display: flex; justify-content: flex-end">
        <el-button
          style="
            color: var(---, #262729);
            background: var(---300, #f1f2f2);
            border: none !important;
          "
          class="dialog_btn"
          @click="dialogTranslation = false"
          >取 消</el-button
        >
        <el-button
          style="
            color: var(--white-dark-white, #fff);
            background: #e2426b;
            border: none !important;
          "
          type="primary"
          @click="sub_fanyi"
          class="dialog_btn"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Message } from "element-ui";
import {
  errorFeedback,
  newDemandCheck,
  partChBook,
  manual,
  Deletes,
  newDeletes,
  batchDelete,
  newRead,
  newUnreadNumber,
  getfileformat,
  newYiCaiTask_id,
  categoryList,
} from "@/Api/manage";
import { personalMessage, fetchMessage } from "@/Api/userMessage";
import {
  getData,
  deleteRecord,
  download,
  appletManager,
} from "@/Api/translation";
import { downloadXlsx } from "../../../utils/xlsx";
import Vue from "vue";
import { eventBus } from "../../../eventBus";
import $ from "jquery";
import axios from "axios";
export default {
  data() {
    return {
      Height: 0,
      searchInput: "",
      type: [
        {
          book_type: "中文书",
          type: "zhBook",
          case: 0,
        },
        {
          book_type: "英文书",
          type: "enBook",
          case: 0,
        },
        {
          book_type: "中文文献 ",
          type: "zhDoc",
          case: 0,
        },
        {
          book_type: "英文文献",
          type: "enDoc",
          case: 0,
        },
        {
          book_type: "DOI",
          type: "DOI",
          case: 0,
        },
        {
          book_type: "PMID",
          type: "PMID",
          case: 0,
        },
        {
          book_type: "PMC",
          type: "PMC",
          case: 0,
        },
        {
          book_type: "链接地址",
          type: "Url",
          case: 0,
        },
        {
          book_type: "标准",
          type: "standard",
          case: 0,
        },
        {
          book_type: "专利 ",
          type: "patent",
          case: 0,
        },
        {
          book_type: "附件",
          type: "annex",
          case: 0,
        },
        {
          book_type: "负面消息",
          type: "negative",
          case: 0,
        },
      ],
      buttonIndex: 0,
      buttonStates: 0,
      states: [
        { task: "处理成功", status: 4 },
        { task: "正在处理", status: 2 },
        { task: "待处理", status: 1 },
        { task: "处理失败", status: 3 },
        { task: "相似推荐", status: 5 },
      ],
      radio: 0,
      origin: 0,
      value1: "",
      value2: "",
      valueDate: [],
      time_or: parseInt(new Date().getTime() / 1000) - 60 * 60 * 24 * 3,
      // 开始时间限制
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.value2) {
            // eslint-disable-next-line max-len
            return (
              // time.getTime() > new Date(this.value2).getTime() ||
              time.getTime() >= new Date(this.value2).getTime() - 86400000
            );
          }
          return time.getTime() <= new Date(this.value2).getTime() - 86400000;
        },
      },
      // 结束时间限制
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.value1) {
            return time.getTime() < new Date(this.value1).getTime();
          }
          return time.getTime() <= new Date(this.value1).getTime() - 86400000;
        },
      },
      tableData: [],
      historyFileList: [],
      batchs: false,
      Types: ["all"],
      condition: ["all"],
      needsList: {
        page: 1,
        size: 10,
        sort_by: "time_desc",
        con_type: [],
        status: [],
        start_date: "",
        end_date: "",
        content: "",
        read: 0,
        source: 0,
      },
      start_time: "",
      end_time: "",
      currentPage: 1, // 当前页码
      totalCount: null, //总条数
      dialogVisible: false,
      isExpanded: false,
      chBook: [],
      partch: {},
      dialogMessage: false,
      anew: {
        task_id: "",
        msg: "",
        type: "其他",
      },
      multipleSelection: [],
      rules: {
        msg: [
          { required: true, message: "请输入反馈消息", trigger: "blur" },
          { min: 3, message: "反馈信息内容至少10字符", trigger: "blur" },
        ],
      },
      intervalId: null,
      statusType: "",
      dialogVisible01: false,
      form: {
        url: [],
        password: [],
      },
      formLabelWidth: "80px",
      passType: 0,
      dialogVisible02: false,
      causeFail: "",
      num_total: 0,
      sub_or: "",
      dialogTranslation: false,
      remainderNumber: 0,
      loading: false,
      cascaderValue: null,
      typeOptions: [
        {
          value: "online",
          label: "在线预览",
        },
        {
          value: "translate",
          label: "下载译文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "Word版",
            },
          ],
        },
        {
          value: "origin",
          label: "下载原文",
          children: [
            {
              value: "pdf",
              label: "PDF版",
            },
            {
              value: "word",
              label: "Word版",
            },
          ],
        },
        {
          value: "compare",
          label: "下载对照版(除pdf中译英外)",
        },
      ],
      row_copy: {},
      current_page: 0,
      submit_txt: "提交查找",
      loading_sub: false,
      codeType: {
        zhBook: { text: "中文书", color: "#d9a673" },
        enBook: { text: "英文书", color: "#d9d973" },
        PMID: { text: "PMID", color: "#73d9a6" },
        PMC: { text: "PMC", color: "#73d973" },
        DOI: { text: "DOI", color: "#8676bd" },
        Url: { text: "链接地址", color: "#73d9d9" },
        zhDoc: { text: "中文文献", color: "#73a6d9" },
        enDoc: { text: "英文文献", color: "#7373d9" },
        patent: { text: "专利", color: "#a673d9" },
        standard: { text: "标准", color: "#d973d9" },
        annex: { text: "附件", color: "#73d8d9" },
        negative: { text: "负面消息", color: "#AC2909" },
      },
      sourceType: {
        QQ: "qq群",
        Wechat: "微信群",
        Applet: "小程序&PC",
        ServiceNumber: "服务号",
      },
      sDate: "",
      eDate: "",
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 170; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 170;
    };
    this.get_personalMessage();
    // 监听当前页面 显示状态
    window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

    // 当页面被销毁时 移除监听
    this.$on("hook:beforeDestroy", () => {
      // console.info("ws 我被销毁了, 移除监听>>>");
      window.removeEventListener(
        "visibilitychange",
        this.hanldeVisiblityChange
      );
    });
  },
  created() {
    let date = new Date();
    var dateStare = new Date().setDate(new Date().getDate() - 3);
    let year1 = new Date(dateStare).getFullYear(); // 年
    let year = date.getFullYear(); // 年

    let month = date.getMonth() + 1; // 月
    month = month < 10 ? "0" + month : month;
    let month1 = new Date(dateStare).getMonth() + 1; // 月
    month1 = month1 < 10 ? "0" + month1 : month1;
    let day = date.getDate(); // 日
    day = day < 10 ? "0" + day : day;
    let day1 = new Date(dateStare).getDate(); // 日
    day1 = day1 < 10 ? "0" + day1 : day1;
    this.value1 = this.sDate = year1 + "-" + month1 + "-" + day1;
    this.value2 = this.eDate = year + "-" + month + "-" + day;
    this.needsList.start_date = this.value1;
    this.needsList.end_date = this.value2;
    this.valueDate = [this.value1, this.value2];
    this.start_time = this.value1;
    this.end_time = this.value2;
    if (this.$route.query.origins) {
      this.origin = Number(this.$route.query.origins);
    }
    this.loading = true;
    this.demandList();
    this.dataRefreh();
    this.get_data();
  },
  methods: {
    get_data() {
      categoryList()
        .then((res) => {
          if (res.data.error_code == 0) {
            let resData = res.data.data;
            let keys = Object.keys(resData);
            let arr = [];
            let objArr = [];

            keys.forEach((item) => {
              let obj = {};
              obj.type = item;
              obj.book_type = resData[item];
              obj.case = 0;
              objArr.push(obj);
              arr.push(resData[item]);
            });
            console.log(arr);
            console.log(objArr);
            console.log(keys);
            this.type = objArr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDownloadType(val, row, index) {
      if (val) {
        if (val[0] == "online") {
          this.historyFileList.forEach((item, index) => {
            if (item.task_id == row.yicai_task_id) {
              this.toEastrans_online(item);
            }
          });
          this.cascaderValue = [];
          return;
        } else {
          this.handleDownLoad(row.yicai_task_id, val[0], val[1]);
        }
      }

      this.cascaderValue = [];
    },
    toEastrans_online(row) {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      let event = localStorage.getItem("code") + "|" + tmp;
      let url =
        "id=" +
        row.task_id +
        "&pageNum=" +
        row.pageNum +
        "&from=" +
        row.langFrom +
        "&to=" +
        row.langTo +
        "&code=" +
        event +
        "&type=" +
        "pdf";
      window.open(
        "http://eastrans.newacademic.net/#/transition?" + url,
        "_blank"
      );
    },
    //预览
    preview(e) {
      var task_id = e.read_url.split("task_id=");
      console.log();
      this.$router.push({
        name: "Read",
        query: {
          task_id: task_id[1],
        },
      });
    },
    closeDialog() {
      this.demandList();
    },
    // 选择需求状态
    beforeHandleCommand(command) {
      return {
        command: command,
      };
    },
    // 下拉框选择
    handleCommand(command) {
      this.needsList.sort_by = command.command;
      this.needsList.page = 1;
      this.demandList();
    },
    operateHandleCommand(command) {
      if (command.command == "del") {
        this.remove(command.row);
      } else if (command.command == "feedback") {
        this.feedback(command.row);
      } else if (command.command == "causeFail") {
        this.links(command.row);
      } else if (command.command == "resubmit") {
        this.resubmit(command.row);
        // console.log(command.row.content)
      }
    },
    // 过期重新提交
    resubmit(row) {
      let that = this;
      let mes = JSON.parse(sessionStorage.getItem("mes"));
      let params = {
        type: 2,
        text: row.content,
        organ_code: mes.org_code,
        force_type: 2,
        type: 2,
        is_artificial: 0,
        force_submint: false,
      };
      that.returnMessageAjax = $.ajax({
        url: process.env.VUE_APP_API_BASE_URL + "user/consultation/",
        data: JSON.stringify(params),
        timeout: 50000, // 请求超时时间
        type: "Post",
        headers: {
          token: localStorage.getItem("token"),
        },
        processData: false, // 不处理数据
        contentType: false, // 不设置内容类型
        xhrFields: {
          onprogress: function (e) {},
          onload: function (e) {},
        },
        timeout: 1000 * 60 * 2,
        complete: function (XMLHttpRequest, status) {
          if (XMLHttpRequest.responseJSON) {
            let responseJson = XMLHttpRequest.responseJSON;
            if (responseJson.error_code === 0) {
              that.$message({
                message: responseJson.msg,
                type: "success",
              });
              that.demandList();
            } else {
              that.$message({
                message: responseJson.msg,
                type: "error",
              });
            }
          }
        },
      });
    },
    operateCommand(index, row, command) {
      return { index, row, command };
    },
    // 获取文件历史列表
    getFileList(tabData) {
      let data = {
        page: 1,
        name: "",
        state: 0,
      };
      getData(data)
        .then((res) => {
          if (res.data.status == 0) {
            this.historyFileList = res.data.data ? res.data.data : [];
            if (this.historyFileList.length == 0) {
            } else {
              this.historyFileList.forEach((item) => {
                // 转换来源语种
                switch (item.langFrom) {
                  case "en":
                    this.$set(item, "langFromCh", "英文");
                    break;
                  case "zh":
                    this.$set(item, "langFromCh", "中文（简体）");
                    break;
                }
                // 转换目的语种
                switch (item.langTo) {
                  case "en":
                    this.$set(item, "langToCh", "英文");
                    break;
                  case "zh":
                    this.$set(item, "langToCh", "中文（简体）");
                    break;
                }
              });
            }
          } else {
            this.historyFileList = [];
          }
          tabData.forEach((item, index) => {
            let filteredTasks = this.historyFileList.filter(
              (task) => task.task_id === item.yicai_task_id
            );
            if (filteredTasks.length > 0) {
              this.$set(item, "yicai_status", filteredTasks[0].task_status);
            }
          });
          this.tableData = tabData;
          this.loading = false;
        })
        .catch((err) => {
          this.tableData = tabData;
          this.loading = false;
        });
    },
    // 需求列表
    demandList() {
      if (this.Types.length > 1) {
        this.needsList.con_type = this.Types.filter((item) => item !== "all");
      } else {
        this.needsList.con_type = this.Types;
      }
      if (this.condition.length > 1) {
        this.needsList.status = this.condition.filter((item) => item !== "all");
      } else {
        this.needsList.status = this.condition;
      }
      let que = {
        uc: localStorage.getItem("code"),
      };
      this.currentPage = this.needsList.page;
      newDemandCheck(this.needsList, que)
        .then((res) => {
          let result_data = [];
          res.data.data.data.forEach((item, index) => {
            if (
              (item.status == 4 || item.status == 5) &&
              item.short_link.length > 0
            ) {
              item.down_file = this.disposeUrl(item);
            } else {
              item.down_file = [];
            }
            let down_url = item.down_url.join(",");
            let url_or = down_url.includes("下载地址");
            if (url_or) {
              let parts = down_url.split("下载地址");
              let urls_ = parts
                .map((part) => part.match(/https?:\/\/[^\s]+/g))
                .filter(Boolean)
                .flat();
              item.urls = urls_;
            } else {
              item.urls = down_url.split(",");
            }
            if (
              (item.status == 4 || item.status == 5) &&
              down_url !== "" &&
              item.urls[0] &&
              item.urls &&
              item.urls.length > 0 &&
              item.demand_code !== "zhName" &&
              item.demand_code !== "enName"
            ) {
              item.urls.forEach((item_url, index_url) => {
                if (item_url) {
                  result_data.push({
                    id: item.id,
                    url: item_url,
                  });
                }
              });
            }
          });
          this.totalCount = res.data.data.total;
          if (result_data.length > 0) {
            this.isFile(result_data, res.data.data.data);
          } else {
            this.getFileList(res.data.data.data);
            this.notNumbers();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    disposeUrl(row) {
      let formUrl = [];
      let short_link = row.short_link.join(",");
      if (short_link.indexOf("文档密码：") !== -1) {
        let pass = short_link.split("文档密码：");
        let obj = {
          url: pass[0],
          password: pass[1].slice(0, 4),
          passType: 1,
        };
        formUrl.push(obj);
      } else if (short_link.indexOf("提取码：") !== -1) {
        let pass = short_link.split("提取码：");
        let obj = {
          url: pass[0],
          password: pass[1].slice(0, 4),
          passType: 2,
        };
        formUrl.push(obj);
      } else {
        this.passType = 0;
        if (short_link.indexOf(",") !== -1) {
          let urlArray = short_link.split(",");
          urlArray.forEach((item, index) => {
            let urls_ = "";
            urls_ = item.replace(/\n/g, "      ");
            if (urls_.indexOf("      ") !== -1) {
              let urlList_ = urls_.split("      ");
              urlList_.forEach((item_) => {
                let url_obj = {
                  url: "",
                  passType: 0,
                };
                if (item_.indexOf("http:") !== -1) {
                  url_obj.url = item_.substring(item_.lastIndexOf("http:"));
                  formUrl.push(url_obj);
                }
              });
            } else {
              let url_obj = {
                url: item,
                passType: 0,
              };
              formUrl.push(url_obj);
            }
          });
        } else {
          let urls = "";
          urls = short_link.replace(/\n/g, "      ");
          if (urls.indexOf("      ") !== -1) {
            let urlList = urls.split("      ");
            urlList.forEach((item) => {
              let url_obj = {
                url: "",
                passType: 0,
              };
              if (item.indexOf("http:") !== -1) {
                url_obj.url = item.substring(item.lastIndexOf("http:"));
                formUrl.push(url_obj);
              }
            });
          } else {
            let url_obj = {
              url: short_link,
              passType: 0,
            };
            formUrl.push(url_obj);
          }
        }
      }
      return formUrl;
    },
    isFile(data, tabData) {
      getfileformat({
        result_data: data,
      })
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              const matchingItem = tabData.find(
                (item_list) => item_list.id === item.id
              );
              if (matchingItem) {
                if (item.file_extension) {
                  this.$set(
                    matchingItem,
                    "file_extension",
                    item.file_extension
                  );
                }
              }
            });
            this.loading = false;
            this.getFileList(tabData);
            this.notNumbers();
          } else {
            this.tableData = tabData;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.tableData = tabData;
          this.loading = false;
        });
    },
    // 序号
    indexMethod(index) {
      return index + 1;
    },
    onSubmit() {
      this.needsList.content = this.searchInput;
      this.reset_search();
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    search_type_() {
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 筛选类型
    search_type(item, i) {
      if (this.type[i].case == 0) {
        this.type[i].case = 1;
        let index = this.Types.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        this.Types.push(item.type);
        // console.log(this.Types);
      } else {
        this.type[i].case = 0;
        let index = this.Types.indexOf(item.type);
        if (index > -1) {
          //大于0 代表存在，
          this.Types.splice(index, 1); //存在就删除
        }
        // console.log(this.Types);
        if (this.Types.length == 0) {
          this.Types = ["all"];
        }
      }
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    search_states_() {
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 筛选状态
    search_states(item, i) {
      if (this.states[i].state == 0) {
        this.states[i].state = 1;
        let index = this.condition.indexOf("all");
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        this.condition.push(item.status);
      } else {
        this.states[i].state = 0;
        let index = this.condition.indexOf(item.status);
        if (index > -1) {
          //大于0 代表存在，
          this.condition.splice(index, 1); //存在就删除
        }
        if (this.condition.length == 0) {
          this.condition = ["all"];
        }
      }
      this.needsList.page = 1;
      this.demandList();
    },
    // 是否已读
    radios(label) {
      this.loading = true;
      this.needsList.read = label;
      this.needsList.page = 1;
      this.demandList();
    },
    origins(label) {
      this.needsList.source = label;
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    changeDate() {
      if (this.valueDate && this.valueDate.length > 0) {
        this.start_time = this.valueDate[0];
        this.end_time = this.valueDate[1];
      } else {
        this.start_time = null;
        this.end_time = null;
      }
      this.needsList.start_date = this.start_time;
      this.needsList.end_date = this.end_time;
      this.demandList();
    },
    // 重置
    reset() {
      this.type.forEach((item) => {
        item.case = 0;
      });
      this.states.forEach((item) => {
        item.state = 0;
      });
      this.condition = [];
      this.Types = ["all"];
      this.needsList.start_date = this.sDate;
      this.needsList.end_date = this.eDate;
      this.value1 = this.sDate;
      this.value2 = this.eDate;
      this.start_time = this.sDate;
      this.end_time = this.eDate;
      this.valueDate = [this.sDate, this.eDate];
      this.needsList.page = 1;
      this.needsList.read = 0;
      this.radio = 0;
      this.origin = 0;
      this.searchInput = "";
      this.needsList.content = "";
      this.needsList.source = 0;
      this.loading = true;
      this.demandList();
    },
    reset_search() {
      this.type.forEach((item) => {
        item.case = 0;
      });
      this.states.forEach((item) => {
        item.state = 0;
      });
      this.condition = [];
      this.Types = ["all"];
      this.needsList.start_date = this.start_time;
      this.needsList.end_date = this.end_time;
      this.value1 = this.start_time;
      this.value2 = this.end_time;
      this.needsList.page = 1;
      this.needsList.read = 0;
      this.radio = 0;
      this.origin = 0;

      this.needsList.source = 0;
      this.loading = true;
      this.demandList();
    },
    // 多选  批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length > 0) {
        this.batchs = true;
      } else {
        this.batchs = false;
      }
    },
    batch() {
      this.batchs = false;
    },
    // 导出exls表格
    onClickDown() {
      let datalist = [];
      datalist.push(["需求类型", "需求内容", "下载地址"]);
      this.multipleSelection.forEach((item) => {
        let text = this.codeType[item.demand_code].text;
        datalist.push([text, item.content, item.short_link]);
      });
      downloadXlsx(datalist, "需求数据统计.xlsx");
      this.$refs.multipleTable.clearSelection();
      this.batchs = false;
    },
    // 批量删除
    deleBatch() {
      this.$confirm("确定删除勾选的数据？", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      }).then(() => {
        this.loading = true;
        let task_id = this.multipleSelection.map((item) => item.task_id);
        newDeletes({
          task_id: task_id.join(","),
        }).then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.demandList();
          this.$refs.multipleTable.clearSelection();
          this.batchs = false;
          newRead({
            task_id,
          })
            .then((result) => {
              this.notNumbers();
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
    },
    change(type) {
      if (type == "start") {
        this.needsList.start_date = this.value1;
      }
      if (type == "end") {
        this.needsList.end_date = this.value2;
      }
      this.needsList.page = 1;
      this.demandList();
    },
    // 链接地址
    links(row) {
      if (row.status !== 3) {
        let short_link = row.short_link.join(",");
        this.dialogVisible01 = true;
        if (short_link.indexOf("文档密码：") !== -1) {
          this.passType = 1;
          let pass = short_link.split("文档密码：");
          this.form.url.push(pass[0]);
          this.form.password.push(pass[1].slice(0, 4));
        } else if (short_link.indexOf("提取码：") !== -1) {
          this.passType = 2;
          let pass = short_link.split("提取码：");
          this.form.url.push(pass[0]);
          this.form.password.push(pass[1].slice(0, 4));
        } else {
          this.passType = 0;
          if (short_link.indexOf(",") !== -1) {
            let urlArray = short_link.split(",");
            this.form.url = [];
            urlArray.forEach((item, index) => {
              let urls_ = "";
              urls_ = item.replace(/\n/g, "      ");
              if (urls_.indexOf("      ") !== -1) {
                let urlList_ = urls_.split("      ");
                urlList_.forEach((item_) => {
                  if (item_.indexOf("http:") !== -1) {
                    this.form.url.push(
                      item_.substring(item_.lastIndexOf("http:"))
                    );
                  }
                });
              } else {
                this.form.url.push(item);
              }
            });
          } else {
            let urls = "";
            urls = short_link.replace(/\n/g, "      ");
            if (urls.indexOf("      ") !== -1) {
              let urlList = urls.split("      ");
              this.form.url = [];
              urlList.forEach((item) => {
                if (item.indexOf("http:") !== -1) {
                  this.form.url.push(item.substring(item.lastIndexOf("http:")));
                }
              });
            } else {
              this.form.url = [];
              this.form.url.push(row.down_url);
            }
          }
          return;
        }
      }
      if (row.status == 3) {
        this.dialogVisible02 = true;
        if (row.fail_reason == "" || row.fail_reason == null) {
          this.causeFail = "暂无资源！";
        } else {
          this.causeFail = row.fail_reason;
        }
      }
    },
    disposeRead(row) {
      if (row.read == 1) {
        newRead({
          task_id: [row.task_id],
        })
          .then((res) => {
            // console.log(res);
            this.demandList();
          })
          .catch((err) => {
            console.log(err);
          });
       
      }
    },
    downFileOpen(item, row, i) {
      this.disposeRead(row);
      if (item.passType == 0 && i <= 1) {
        window.open(item.url, "_blank");
      } else {
        this.form.url = row.down_file.map((item) => item.url);
        this.form.password = row.down_file.map((item) => item.password);
        this.passType = row.down_file[0].passType;
        this.dialogVisible01 = true;
      }
    },
    // 打开链接
    openLink() {
      window.open(this.form.url[0], "_blank");
      this.dialogVisible01 = false;
    },
    openLink01(links) {
      window.open(links, "_blank");
    },
    // 复制链接
    copyLink() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.url[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制链接
    copyLink01(item) {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = item;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    // 复制密码
    copyPass() {
      //创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = this.form.password[0];
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      // 执行浏览器复制命令
      /// 复制命令会将当前选中的内容复制到剪切板中
      /// 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: "复制成功",
        type: "success",
      });
      ///复制成功后再将构造的标签 移除
      oInput.remove();
    },
    notNumbers() {
      newUnreadNumber()
        .then((res) => {
          if (res.data.data.num > 0) {
            this.num_total = res.data.data.num;
          } else {
            this.num_total = 0;
          }
          eventBus.$emit("updateNum", res.data.data.num);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 更多结果
    mores(row) {
      this.disposeRead(row);
      if (row.similar_url  && row.demand_code == "zhBook") {
        // && row.status == 4
        
        // let url = "http://ql-test.newacademic.net:8001/#/booksSet?";
        // if (row.result_info) {
        //   if (row.result_info.similar_id) {
        //     url = url + `similar_id=${row.result_info.similar_id}`;
        //   } else if (row.result_info.TaoShuId) {
        //     url = url + `TaoShuId=${row.result_info.TaoShuId}`;
        //   }
        //   window.open(url, "_blank");
        // }
        // return;
        window.open(row.similar_url, "_blank");
        return;
      }
     
      // return
      // let link = "http://192.168.66.18:8007/api/user/similar_recommend/";
      // let link = "https://ql-test.newacademic.net/api/user/similar_recommend/";
      let link = process.env.VUE_APP_API_BASE_URL + "user/similar_recommend/";
      // let link = "https://xcx.newacademic.net/api/user/similar_recommend/";
      this.dialogVisible = true;
      this.current_page = 0;
      this.isExpanded = false;
      this.chBook = [];
      this.loading_sub = true;
      this.$http
        .get(link, {
          params: {
            text: row.content,
            text_code: row.demand_code,
            task_id: row.task_id,
            status: row.status,
          },
        })
        .then((res) => {
          this.loading_sub = false;
          if (res.data.error_code == 0) {
            this.current_page = 0;
            this.isExpanded = false;
            this.chBook = res.data.data;
            if (this.chBook.length > 0) {
              this.dialogVisible = true;
            } else {
              Message({
                message: "暂无更多数据！！",
                type: "warning",
              });
            }
          }
          if (res.data.error_code == 1) {
            Message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.loading_sub = false;
          console.log(err);
        });
    },
    prevPage() {
      if (this.submit_txt == "提交中") {
        return;
      }
      if (this.current_page > 0) {
        this.current_page--;
        this.isExpanded = false;
      }
    },
    // 下一篇
    nextPage() {
      if (this.submit_txt == "提交中") {
        return;
      }
      if (this.current_page < this.chBook.length - 1) {
        this.current_page++;
        this.isExpanded = false;
      }
    },
    // 提交查找
    submit_similar() {
      let that = this;
      that.submit_txt = "提交中";
      let mes = JSON.parse(sessionStorage.getItem("mes"));
      let text_ = "";
      if (that.chBook[that.current_page].isbn) {
        text_ = that.chBook[that.current_page].isbn;
      } else if (that.chBook[that.current_page].doi) {
        text_ = that.chBook[that.current_page].doi;
      } else {
        text_ = that.chBook[that.current_page].title;
      }
      let cue = {
        type: 2,
        text: text_,
        quote_id: null,
        organ_code: mes.org_code,
        force_type: 2,
        type: 2,
        is_artificial: 0,
        force_submint: true,
      };
      that.returnMessageAjax = $.ajax({
        // url: "http://192.168.66.18:8007/api/user/consultation/",
        // url: "https://ql-test.newacademic.net/api/user/consultation/",
        // url: "https://xcx.newacademic.net/api/user/consultation/",
        url: process.env.VUE_APP_API_BASE_URL + "user/consultation/",
        data: JSON.stringify(cue),
        timeout: 50000, // 请求超时时间
        type: "Post",
        headers: {
          token: localStorage.getItem("token"),
        },
        processData: false, // 不处理数据
        contentType: false, // 不设置内容类型
        xhrFields: {
          onprogress: function (e) {},
          onload: function (e) {},
        },
        timeout: 1000 * 60 * 2,
        complete: function (XMLHttpRequest, status) {
          that.submit_txt = "提交查找";
          if (XMLHttpRequest.responseJSON) {
            let responseJson = XMLHttpRequest.responseJSON;
            if (responseJson.error_code === 0) {
              that.$message({
                message: responseJson.msg,
                type: "success",
              });
              that.dialogVisible = false;
              that.needsList.page = 1;
              that.demandList();
            } else {
              that.$message({
                message: responseJson.msg,
                type: "error",
              });
            }
          }
        },
      });
    },
    // 提交中文书
    tabled(part, index) {
      this.partch = {
        demand: [
          {
            id: 1,
            type_code: "zhBook",
            text: part.title,
            original_text: "<b>" + part.title + "</b>",
            priority: "一般",
            title: part.title,
            ssn: part.id,
          },
        ],
      };
      partChBook(this.partch)
        .then((res) => {
          if (res.data.error_code == 0) {
            if (res.data.data[1].msg == "需求提交成功") {
              this.$message({
                message: res.data.data[1].msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.data[1].msg,
                type: "warning",
              });
            }
            Vue.set(this.chBook[index], "type", res.data.data[1].msg);
          }
        })
        .catch((err) => {});
    },
    // 错误反馈
    feedback(row) {
      this.dialogMessage = true;
      this.anew.task_id = row.task_id;
    },
    // 重新提交任务
    Resubmit() {
      this.anew.msg = this.anew.msg.replace(/\s*/g, ""); // 去除字符串内所有的空格
      this.$refs["anew"].validate((valid) => {
        if (valid) {
          let anew_ = {
            task_id: this.anew.task_id,
            msg: this.anew.type == "其他" ? this.anew.msg : this.anew.type,
          };
          errorFeedback(anew_).then((res) => {
            if (res.data.error_code == 0) {
              this.dialogMessage = false;
              this.anew = {
                task_id: "",
                msg: "",
                type: "其他",
              };
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.demandList();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 单个删除需求
    remove(row) {
      this.$confirm("是否确定删除该条信息", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      })
        .then(() => {
          this.loading = true;
          newDeletes({
            task_id: row.task_id,
          })
            .then((res) => {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.demandList();
              if (row.read === 1) {
                newRead({
                  task_id: [row.task_id],
                })
                  .then((result) => {
                    this.notNumbers();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    allRead() {
      this.$confirm("是否确定全部标记为已读", {
        confirmButtonText: "确认标记",
        cancelButtonText: "取消",
        type: "warning",
        cancelButtonClass: "cssCancel",
        confirmButtonClass: "cssConfirm",
      })
        .then(() => {
          newRead({
            task_id: ["all"],
          })
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          this.demandList();
          this.notNumbers();
        })
        .catch(() => {});
    },
    // 分页跳转
    handleCurrentChange(val) {
      this.needsList.page = val;
      this.loading = true;
      this.demandList();
    },
    // 每页显示条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.loading = true;
      this.needsList.page = 1;
      this.demandList();
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新" + new Date());
        this.demandList(); //加载数据函数
      }, 30000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    hanldeVisiblityChange() {
      if (document.visibilityState === "hidden") {
        // console.log("ws  hidden>>>关闭定时器");
        this.clear();
      } else if (document.visibilityState === "visible") {
        // console.log("ws  visible>>>启动定时器");
        this.dataRefreh();
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.read == 1) {
        return "";
      }
    },
    getCellStyle({ row, column }) {
      if (
        column.type === "selection" &&
        row.read == 1 &&
        (row.status == 4 || row.status == 3 || row.status == 5)
      ) {
        return {};
      }
      return "";
    },
    getCellClassName({ row, column }) {
      if (
        column.type === "selection" &&
        row.read == 1 &&
        (row.status == 4 || row.status == 3 || row.status == 5)
      ) {
        return "half-border-left"; // 动态添加类名
      }
      return "";
    },

    numberToChinese(num) {
      const map = {
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "七",
        8: "八",
        9: "九",
        10: "十",
      };
      return map[num] || num; // 如果不在范围内，返回原值
    },
    // 获取
    get_personalMessage() {
      if (!sessionStorage.getItem("mes")) {
        return;
      }
      let mes_copy = JSON.parse(sessionStorage.getItem("mes"));
      appletManager({
        user_code: mes_copy.code,
      })
        .then((res) => {
          if (res.data.data && res.data.data.info) {
            this.remainderNumber = res.data.data.info[0].surplus;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async processUrls(row) {
      for (const url of row.urls) {
        try {
          let pdf_name = row.title + "_" + new Date().getTime() + ".pdf";
          const res = await axios.get(
            "https://journal.codo.my/new_download_file",
            {
              params: { url },
              responseType: "blob", // 设置返回类型为 blob，用于处理文件流
            }
          );
          if (res && res.data) {
            // 文件流获取成功，处理后续逻辑
            const text = await res.data.text();
            let parsedData;
            try {
              parsedData = JSON.parse(text); // 尝试解析为 JSON
            } catch (error) {
              parsedData = null; // 如果解析失败，说明是正常的文件流
            }

            if (parsedData && parsedData.code === 0) {
              console.error("文件请求失败:", parsedData.msg);
              continue; // 跳过当前 URL，处理下一个
            }
            const blob = new Blob([res.data]);
            const file = new File([blob], pdf_name, { type: blob.type });
            // 准备 FormData，用于上传文件
            const formData = new FormData();
            formData.append("file_obj", file);
            formData.append("langFrom", "en");
            formData.append("langTo", "zh");
            formData.append("file_num", 1);
            // 上传文件
            const uploadResponse = await axios.post(
              "https://eastrans.newacademic.net/api/translate/upload/",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  USID: window.localStorage.getItem("token_USID"),
                },
              }
            );
            if (uploadResponse.data.status == 0) {
              const task_id = uploadResponse.data.data.task_id_list[0];
              const obg = {
                task_id: row.task_id,
                yicai_task_id: task_id,
              };
              this.relevancyId(obg);
            } else {
              this.$message.error(uploadResponse.data.msg);
            }
            this.error_dispose(row);
            return; // 成功后终止循环和函数
          }
        } catch (error) {
          console.error(`文件流获取失败，尝试下一个 URL: ${url}`, error);
        }
      }
      // 如果所有 URL 都失败，显示错误消息
      // console.error("所有 URL 请求失败，无法处理文件流");
      // this.$message.error("所有文件请求失败，无法上传");
      this.$message.error("PDF文件请求失败，暂不支持翻译");
      this.error_dispose(row);
    },
    error_dispose(row) {
      const targetObject = this.tableData.find((item) => item.id === row.id);
      if (targetObject) {
        targetObject.sub_or_id = "";
        this.$set(targetObject, "sub_or_id", "");
        this.$set(row, "sub_or_id", "");
        this.tableData = JSON.parse(JSON.stringify(this.tableData));
      }
    },
    sub_fanyi() {
      let row = this.row_copy;
      if (row.sub_or_id) {
        return;
      } else {
        const targetObject = this.tableData.find((item) => item.id === row.id);
        if (targetObject) {
          targetObject.sub_or_id = targetObject.id;
          this.$set(targetObject, "sub_or_id", targetObject.id);
          this.$set(row, "sub_or_id", targetObject.id);
          this.tableData = JSON.parse(JSON.stringify(this.tableData));
        } else {
          this.dialogTranslation = false;
          return;
        }
      }
      this.dialogTranslation = false;
      this.processUrls(row);
    },
    // 提交翻译
    translationSub(row) {
      if (this.remainderNumber <= 0) {
        this.$message({
          message: "您的文档翻译次数已达上限！",
          type: "warning",
        });
      } else {
        this.row_copy = row;
        this.dialogTranslation = true;
      }
    },
    relevancyId(obg) {
      newYiCaiTask_id(obg)
        .then((res) => {
          this.remainderNumber = this.remainderNumber - 1;
          this.demandList();
        })
        .catch((err) => {});
    },
    // 查看翻译结果
    translationChick(row) {
      this.historyFileList.forEach((item, index) => {
        if (item.task_id == row.yicai_task_id) {
          this.handleDownLoad(item.task_id);
        }
      });
    },
    // 下载操作
    handleDownLoad(id, version, type) {
      let down_id = {
        task_id: "['" + id + "']",
        version: version,
        type: type,
      };
      download(down_id)
        .then((res) => {
          if (res.data.status == 0) {
            if (res.data.data[0].status == 0) {
              window.open(res.data.data[0].url, "_blank");
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 在线实时翻译
    translationRealTime(row) {
      this.historyFileList.forEach((item, index) => {
        if (item.task_id == row.yicai_task_id) {
          this.toEastrans(item);
        }
      });
    },
    toEastrans(row) {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      let event = localStorage.getItem("code") + "|" + tmp;
      let url =
        "id=" +
        row.task_id +
        "&pageNum=" +
        row.pageNum +
        "&from=" +
        row.langFrom +
        "&to=" +
        row.langTo +
        "&code=" +
        event +
        "&type=" +
        "realTime";
      console.log("http://eastrans.newacademic.net/#/transition?" + url);
      window.open(
        "http://eastrans.newacademic.net/#/transition?" + url,
        "_blank"
      );
    },
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
};
</script>

<style lang='less' scoped>
.management_box {
  font-family: PingFangSC-Regular, PingFang SC;
  .header {
    display: flex;
    max-width: 1400px;
    min-width: 600px;
    height: 50px;
    line-height: 50px;
    h2 {
      font-size: 18px;
    }
    .input {
      // width: 380px;
      display: flex;
      align-items: center;
      line-height: 50px;
      .el-input {
        /deep/ .el-input__inner {
          width: 360px;
          height: 36px;
          line-height: 36px;
          border: 1px solid var(--Gray-200, #e7e5e4);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          // border-radius: 15px;
        }
      }
      .el-button {
        margin: 0 0 0 16px;
        border-radius: 4px;
        // height: 36px;
        // line-height: 36px;
        border: 1px solid #e2426b;
        background: #e2426b;
        padding: 10px 23px;
      }
    }
    .el-button--mini {
      width: 90px;
    }
  }
  .search_field {
    max-width: 1400px;
    color: var(---, #262729);
    .sifts {
      display: flex;
      margin: 15px 0;

      .sifts_tit {
        width: 75px;
        line-height: 20px;
        font-weight: 600;
      }
      .choose {
        width: calc(100% - 100px);
        .reset_css {
          background: #e2426b;
          border-radius: var(---4, 4px);
          background: #e2426b;
          border-color: #e2426b;
          color: var(---Color-7, #fff);
          line-height: 22px;
          display: flex;
          padding: var(---4, 4px) 24px;
          justify-content: center;
          align-items: center;
          gap: var(---4, 4px);
        }
        span {
          font-weight: normal;
        }
        .bgcolor {
          background-color: #f5f3ff;
          color: #aaa;
        }
        .chosen {
          color: #ffffff;
          background-image: linear-gradient(to bottom, #b14a75, #b14a75);
        }
        /deep/ .el-radio__label {
          color: var(---, #8d8f94);
        }
        /deep/ .el-radio__input.is-checked + .el-radio__label {
          color: var(---, #262729);
        }
        /deep/ .el-radio__input.is-checked .el-radio__inner {
          border-color: #e2426b;
          background: #e2426b;
        }
        /deep/ .el-range-separator {
          width: 25px;
        }
      }
      .checkbox_group_css {
        /deep/ .el-checkbox__original {
          display: none;
        }
        /deep/ .el-checkbox__label {
          color: var(---, #8d8f94);
        }
        /deep/ .is-checked {
          .el-checkbox__label {
            color: var(---, #262729);
          }
          .el-checkbox__inner {
            background: #e2426b !important;
            border-color: #e2426b;
          }
        }
      }
    }
  }
  .tables {
    margin: auto;
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #e2426b;
      border-color: #e2426b;
    }
    /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #e2426b;
      border-color: #e2426b;
    }
    /deep/ .el-checkbox__inner:hover {
      border-color: #e2426b;
    }
    /deep/ .el-table .el-table__cell {
      padding: 0 !important;
    }
    /deep/ .el-table .el-table__cell > .cell {
      padding: 12px 10px 12px 10px !important;
    }
    /deep/ .el-table th.el-table__cell > .cell {
      font-weight: 600;
      color: var(--Gray-Gray-500, #a1a5b7);
    }
    .row_text_css {
      // width: calc(100% - 130px);
      max-width: 240px;
      display: inline-block;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .status_btn_css {
      display: flex;
      padding: 6px var(---8, 8px);
      align-items: center;
      gap: 6px;
      border-radius: var(---4, 4px);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: inline-block;
    }
    .file_s {
      gap: var(---8, 8px);
      display: flex;
      justify-content: center;
      align-items: center;
      .down_file_css {
        display: block;
        color: #6b8cfa;
        margin: 0 8px 0 0;
        cursor: pointer;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer {
      // height: 50px;
      // line-height: 50px;
      // width: 80%;
      // background: rgba(0, 0, 0, 0.5);
      // position: fixed;
      // bottom: 10px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .pag {
    display: flex;
    // justify-content: center;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    margin: 0 0 70px 0;
  }
}
.greater {
  min-width: 650px;
  height: 800px;
  ul::-webkit-scrollbar {
    display: none;
  }
  ul {
    max-height: 500px;
    display: inline-block;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      display: inline-block;
      padding: 15px 5px;
      .newBook {
        p {
          line-height: 20px;
          margin-bottom: 8px;
          display: flex;
        }
        .span_tit {
          width: 100px;
          display: inline-block;
        }
        .span_con {
          width: calc(100% - 100px);
          display: inline-block;
        }
      }
    }
  }
}
.stats {
  .el-button--mini {
    width: 90px;
  }
}
.readable {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 10px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e2426b;
  // background-image: linear-gradient(to right, #6861c9, #8067dc);
  color: #fff;
}
.el-popover {
  p {
    display: inline-block;
    display: flex;
    span {
      display: inline-block;
      min-width: 70px;
    }
  }
}
.oneRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  .el-tag {
    background: #e9f8ff !important;
    color: #42ade2 !important;
    border-color: #e9f8ff !important;
    padding: 4px 8px;
    border-radius: 6px;
    line-height: 18px;
    height: 28px;
  }
}
.ceList {
  .tit {
    span {
      display: inline-block;
    }
  }
  .type {
    span {
      display: inline-block;
    }
  }
}

.el-icon-document-copy {
  display: inline-block;
  margin: 0 10px;
}
.el-icon-document-copy:hover {
  color: #67badb;
  cursor: pointer;
}
.btns {
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.down_links {
  background-image: linear-gradient(to bottom, #b14a75, #b14a75);
  color: white;
  z-index: 99;
}
.down_links1 {
  background-image: linear-gradient(to bottom, #f56c6c, #f56c6c);
  color: white;
  z-index: 99;
}
.dropdown {
  width: 100px;
  ul {
    li {
      text-align: center;
      line-height: 30px;
    }
    li:hover {
      cursor: pointer;
      color: #409eff;
      background: #ecf5ff;
      // color: #4ac264;
      // background: #909399;
    }
  }
}
.link_css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.link_url_css {
  width: 100%;
}
.link_url {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  i {
    display: inline-block;
    cursor: pointer;
  }
  line-height: 24px;
  .url_tit {
    color: var(---, #6b6e73);
  }
  .inputText {
    padding: 0px 24px;
    border-radius: 5px;
    height: 24px;
    color: var(---600, #3c68f8);

    flex: 1 0 0;
    // height: 40px;
    // background-color: #f5f7fa;
    span {
      // display: inline-block;
      margin: 0 0px 0 0;
      text-decoration: underline;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
.password_css {
  display: flex;
  align-items: center;
  width: 100%;
  .inputText {
  }
}
.pdfdown {
  display: flex;
  justify-content: center;
}
.anew_css {
  margin: 7px 0 0 0;
  gap: 16px;
  /deep/ .el-radio {
    display: block;
    margin: 5px 0;
  }
}
.top_css {
  padding: 16px 20px;
  gap: 20px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(102, 105, 119, 0.06);
  margin: 0 0 16px 0;
}
.read_css {
  width: var(---4, 4px);
  height: 53px;
  background: #e2426b;
}

.cssCancel,
.cssConfirm {
  display: flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;

  line-height: 22px;
}
.operate_btn {
  display: flex;
  align-items: center;
  padding: var(---4, 4px) var(---8, 8px);

  border-radius: var(---4, 4px);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: var(--Gray-Gray-200, #f1f1f2);
  color: var(---, #262729);
  border: none;
  position: relative;
  cursor: pointer;
}
.new_icon_css {
  width: 30px;
  position: absolute;
  top: -7px;
  right: 0px;
}
.operate_btn:hover {
  background: var(---500, #d6d7d9);
}
.operate_btn_css {
  display: inline-flex;
  padding: var(---4, 4px) var(---8, 8px);
  align-items: flex-start;
  border-radius: var(---4, 4px);
  background: #f1f1f2;
  line-height: 20px;
  color: var(---, #262729);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.operate_btn_css:hover {
  background: var(---500, #d6d7d9);
}
/deep/ .half-border-left {
  position: relative;
}

/deep/ .half-border-left::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10%; /* 距离顶部 25%，使边框垂直居中 */
  height: 80%; /* 高度为单元格高度的 50% */
  width: 4px; /* 边框宽度 */
  background-color: #e2426b; /* 边框颜色 */
}
.allRead_btn {
  display: flex;
  padding: 5px 9px;
  align-items: center;
  gap: var(---4, 4px);
  color: var(---, #8d8f94);
  border-radius: var(---4, 4px);
  cursor: pointer;
  line-height: 22px;
}
.allRead_btn:hover {
  color: var(---, #262729);
}
.allRead_btn:hover img {
  content: url("../../../assets/imgs/2.2/read_yes.png"); /* 鼠标悬浮时替换图片路径 */
  transform: scale(1); /* 可选：放大效果 */
}
/deep/ .el-table__body-wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius: 5px;
  background-color: #dde;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  //轨道部分
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 5px;
}

/deep/ .el-loading-text {
  color: #e2426b !important;
}
/deep/ .el-loading-spinner i {
  color: #e2426b !important;
}
.z-index {
  position: relative;
  z-index: 100;
}

/deep/ .el-cascader .el-input {
  opacity: 0;
  width: 88px;
}
.cascader_css {
  width: 88px;
  position: relative;
}
.cascader_css:hover .operate_btn {
  background: var(---500, #d6d7d9);
}
.pdf_xiazai {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

/deep/ .recommend_css {
  .el-dialog__close {
    font-weight: 18px;
    width: 16px;
    height: 16px;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 0;
  }
  .prompt_txt {
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    background: var(--Gray-Gray1, #f3f3f3);
    padding: 0px 24px;
    margin: 8px 0 0 0;
    .prompt_txt_css {
      color: var(---, #666b75);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
  .canto_css::-webkit-scrollbar {
    display: none;
  }
  .canto_css {
    padding: 32px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
    .canto_li {
      display: flex;
      align-items: flex-start;

      align-self: stretch;
      .canto_tit {
        display: flex;
        width: 96px !important;
        align-items: center;
        gap: 16px;
        color: var(---, #6b6e73);
        font-weight: 600;
        line-height: 24px;
      }
      .canto_con {
        color: var(---, #262729);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        width: calc(100% - 96px);
        line-height: 24px; /* 150% */
      }
      .isoa_css {
        display: flex;
        padding: 4px 6px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid #f68212;
        margin: 0 0 0 10px;
        .isoa_txt {
          color: #000;
          font-family: Mont;
          font-size: 10px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
  }
  .canto_handoff {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 24px 0 24px 0;
  }
  .canto_none {
    display: flex;
    padding: 24px 24px 24px 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    color: var(----, #aaadb5);
    font-weight: 600;
    line-height: 24px;
    height: 350px;
  }
  .toggle-btn {
    color: #6b8cfa;
    cursor: pointer;
  }
  .submit_btn_css {
    display: flex;
    padding: 0 24px 24px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    .submit_btn {
      display: flex;
      padding: 6px 14px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: #e2426b;
      line-height: 22px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
<style  lang='less'>
.dialog_file_css {
  border-radius: var(---8, 8px) !important;
  .el-dialog__header {
    padding: 24px 16px 0px 24px;
  }
  .el-dialog__title {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
  }

  .el-dialog__body {
    padding: 24px 24px 0px 24px;
  }
  .el-dialog__footer {
    padding: 24px;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  .el-radio__input.is-checked .el-radio__inner {
    color: #e2426b;
    background: #e2426b;
  }
  .el-radio__label {
    color: var(---, #8d8f94);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: var(---, #262729);
  }
  .el-form-item {
    // gap: 24px;
    margin: 0;
  }
}
.el-message-box__container {
  .el-icon-warning {
    color: #e2426b;
  }
}
.dialog_btn {
  padding: 6px 14px;
  line-height: 22px;
}
.cssCancel {
  color: var(---, #262729);
  background: var(---300, #f1f2f2);
  border: none !important;
}
.cssCancel:hover {
  background: var(---300, #f1f2f2);
}
.cssConfirm {
  color: var(--white-dark-white, #fff);
  background: #e2426b;
  border: none !important;
}
.cssConfirm:hover {
  background: #e2426b;
}
.footer_btn {
  display: flex;
  padding: 6px 14px;
  justify-content: flex-end;
  line-height: 22px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #e2426b;
  color: var(--white-dark-white, #fff);
  border: none;
}
.footer_btn:hover {
  background: #e2426b;
  color: var(--white-dark-white, #fff);
}
.train-tree .el-cascader-menu {
  min-width: 120px !important;
}
.popovers {
  /deep/ .el-popover__reference-wrapper {
    width: 100%;
  }
}
</style>

